/* D3 Chart styles */

/* D3 chart tool tip */

.d3-tip {
  line-height: 1;
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .20);
  color: #484848; 
  border-radius: 6px;
  font-size: 12px;
  z-index: 1;
  &__header {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    background-color: #D7E4F8;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-transform: uppercase;
    font-weight: 500;
    height: 34px;
    font-size: 14px;
  }
  &__body {
    padding: 10px 0;
  }
  &__section {
    padding: 0 15px;
    &-title {
      padding: 8px 15px;
      background-color: rgba(0, 0, 0, 0.08);
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      margin-top: 15px;
      small {
        color: rgba(0, 0, 0, 0.6);
        font-size: 85%;
        font-weight: 500;
      }
      &-error {
        padding: 8px 15px;
        background-color: rgba($color-danger, 0.1);
        color: $color-danger;
        font-size: 14px;
        font-weight: 500;
        margin-top: 15px;
      }
      &-purple {
        padding: 8px 15px;
        background-color: rgba($color-purple, 0.1);
        color: $color-purple;
        font-size: 14px;
        font-weight: 500;
        margin-top: 15px;
      }
    }
    p {
      margin-bottom: 10px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      label {
        color: #000;
        margin-right: 5px;
        margin-bottom: 0;
      }
      .pre-text {
        color: #000;
        width: 65px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
    &-box {
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 5px;
      margin-bottom: 5px;
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__footer {
    border-top: 1px solid #ddd;
    font-weight: 500;
    padding: 10px 15px 5px;
    p {
      margin-bottom: 0;
      text-align: center;
      line-height: 16px;
      color: $color-royalblue;
    }
    strong {
      font-weight: 600;
      color: $base-text-color;
    }
  }
}