/*!
* Login page
*/
.user-prelogin {
  min-height: 100vh;
  @include gradient(#FA4616, #FF643B, vertical);
  .gutter-reset {
    margin: 0;
    > div {
      padding: 0;
    }
  }
  .prelogin-container {
    position: relative;
    min-height: 100vh;
    background-color: #fff;
    margin-right: 175px;
    height: 100%;
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 175px;
      top: 0;
      right: -175px;
      background: url(../../assets/img/login_wave.svg) top right no-repeat;
      background-size: cover;
    }
  }
  .login-welcome-text {
    // font-size: 16px;
    color: #525256;
    font-weight: 500;
    margin-top: 45px;
    margin-bottom: 20px;
  }
}
.prelogin-content {
  margin-left: 100px;
  padding-top: 120px;
  padding-bottom: 20px;
  position: relative;
  z-index: 1;
  .text-divider {
    margin: 20px 0;
  }
  .form-group {
    .checkbox {
      color: #777777;
    }
    .form-control {

    }
  }
}
.prelogin-form-container {
  max-width: 315px;
  .form-group {
    border-radius: 20px;
    i {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
    .form-group {
      margin-bottom: 0;
    }
  }
  .form-control {
    height: 40px;
    label {
      color: #5a5a5a;
    }
  }
}
.prelogin-thankyou {
  max-width: 400px;
  margin: 50px auto 0;
  img {
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 20px;
    color: $base-text-color;
  }
  h3 {
    margin-bottom: 10px;
    color: $base-text-color;
  }
}
// Login Intro text
.login_intro {
  width: 600px;
  margin: 0 auto; 
  h1 {
    // font-family: $font-family-base;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 54px;
    font-weight: normal;
    font-weight: 700;
  }
  p {
    font-size: 18px;
    margin-top: 20px;
    color: #fff;
  }
}
.prelogin-pattern {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 754px;
    height: 384px;
    top: 50%;
    left: 50%;
    background: url(../../assets/img/login_pattern.svg) top left no-repeat;
    background-size: 754px 384px;
    margin: -300px 0 0 -445px;
  }
}

.login-logo {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 150px;
  height: auto;
}

.form-extra {
  > a {
    font-size: 14px;
    color: $gray-light;
  }
}

.form-extra {
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.form-action-btn {
  margin-bottom: 30px;
  > .btn {
    font-size: 14px;
    min-width: 125px;
    text-align: center;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    &.loading-btn {
      min-width: auto;
      width: 32px;
      height: 32px;
      padding: 0;
    }
    &.loading-btn--pending {
      background-color: transparent;
      &:before {
        border: 3px solid lighten(#FA4616, 38);
        border-top-color: $brand-primary;
      }
    }
  }
}

.text-copyright {
  text-align: center;
  color: #848486;
  font-size: 12px;
  > a {
    // color: #848486;
  }
}
.credit-card-info {
  max-width: 315px;
  background-color: rgba(0, 33, 165, 0.05);
  padding: 10px 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  > p {
    margin-bottom: 0;
  }
}
.prelogin-btn-group {
  display: block;
  margin: 15px 0 20px;
  .btn {
    min-width: auto;
    padding-left: 15px;
    padding-right: 15px;
    &.btn-g-icon {
      padding: 5px;
    }
  }
}
@media (max-width: 1199.98px){
  .prelogin-btn-group{
    .btn{
      .prelogin-btn-text{
        display: inline-block;
      }
    }
  }
}
@media (max-width: 576px){
  .prelogin-btn-group{
    .btn{
      &:first-child{
        min-width: auto;
      }
      &:nth-child(2){
        min-width: auto;
      }
      .prelogin-btn-text{
        display: none;
      }
    }
  }
}
// Helper
.text-divider {
  position: relative;
  height: 28px;
  text-align: center;
  margin: 10px 0;
  &:before {
    content: "";
    height: 1px;
    background-color: #CECECE;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
  }
  > span {
    padding: 10px;
    font-size: 12px;
    color: #909090;
    background-color: #fff;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
  }
}

.google-signin {
  height: 40px;
  width: 200px;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .abcRioButton {
    -webkit-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 2px 4px 0px rgba(0,0,0,.25) ;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color .218s,border-color .218s,box-shadow .218s;
    transition: background-color .218s,border-color .218s,box-shadow .218s;
    -webkit-user-select: none;
    -webkit-appearance: none;
    background-color: #4285f4;
    background-image: none;
    color: #FFF;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    &:hover {
      -webkit-box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
      box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
    }
   }
  .abcRioButtonBlue {
    height: 40px;
    width: 200px; }
  .abcRioButtonBlue .abcRioButtonContentWrapper {
    border: 1px solid transparent; }
  .abcRioButtonContentWrapper {
    height: 100%;
    width: 100%; }
  .abcRioButtonIcon {
    width: 38px;
    height: 38px;
    padding: 10px;
    background-color: #fff;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    float: left;
  }
  .abcRioButtonContents {
    font-family: Roboto,arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .21px;
    margin-left: 6px;
    margin-right: 6px;
    vertical-align: top;
    text-decoration: none; }
  .abcRioButtonContents {
    line-height: 38px !important;
    > span {
      font-size: 14px !important;
    }
  }
}
.prelogin-info-col {

}
.prelogin-cards {
  margin: 50px auto 20px;
  max-width: 550px;
  .card {
    margin-bottom: 30px;
    border: 4px solid #fff;
    box-shadow: none;
    border-radius: 20px;
    padding: 15px;
    min-height: 250px;
    background-color: transparent;
    color: #fff;
    text-align: center;
    > img {
      width: 60px;
      height: 60px;
      display: inline-block;
      margin: 10px auto 0;
    }
    &.card-translateY {
      transform: translateY(-20px);
    }
  }
  .card-body {
    padding: 5px;
    h4 {
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 15px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.prelogin-info__title {
  text-align: center;
  color: #fff;
  font-weight: 600;
  max-width: 500px;
  margin: 0 auto 0;
  font-size: 28px;
}

// .error {
//   color: #dc3545;
// }
.invalid-feedback {
  color: $color-danger;
}

@media (max-width: 1299.98px) { 
  .login_intro {
    width: 500px;
    h1 {
      font-size: 46px;
    }
    p {
      font-size: 16px;
    }
  }
  .prelogin-pattern {
    &:before {
      width: 650px;
      height: 330px;
      margin: -250px 0 0 -385px;
      background-size: 650px 330px;
    }
  }
  .prelogin-cards {
    // margin: 50px 0 0;
  }
}

@media (max-width: 1199.98px) {
  .login_intro {
    width: 450px;
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 14px;
    }
  }
  .prelogin-pattern {
    &:before {
      width: 450px;
      height: 230px;
      margin: -210px 0 0 -280px;
      background-size: 400px 230px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .user-prelogin {
    .prelogin-container{
      margin-right: 150px;
      &:before{
        width: 150px;
        right: -150px;
      }
      .prelogin-content{
        margin-left: 80px;
      }
    }
    .prelogin-info-col{
      width: 90%;
      max-width: 48%;
    }
  }
}

@media (max-width: 991.98px) {
  .google-signin {
    margin: 0 auto; }
  .user-prelogin {
    .prelogin-container {
      min-height: auto;
      margin-right: 0;
      text-align: center;
      &:before {
        display: none;
      }
      .form-group {
        text-align: left;
      }
    }
  }
  .prelogin-content {
    max-width: 600px;
    margin: 0 auto;
  }
  .login_intro {
    padding: 50px 0;
    p {
      margin-bottom: 0;
    }
  }
  .user-prelogin {
    .login-welcome-text {
      margin-top: 20px;
    }
    .col {
      width: 100%;
      flex-basis: auto;
    }
  }
  .prelogin-form-container {
    margin: 0 auto;
  }
  #google-signin {
    .abcRioButtonBlue {
      margin: 0 auto;
    }
  }
  .credit-card-info {
    margin: 0 auto 20px;
  }
  .prelogin-info__title {
    margin-top: 20px;
  }
  .prelogin-thankyou { 
    margin: 30px auto 20px;
  }
}
@media (max-width: 767.98px) {
  .prelogin-content {
    max-width: 550px;
  }
}
@media (max-width: 575.98px) {
  .login-logo {
    top: 30px;
    left: 20px;
  }
  .prelogin-content {
    padding-top: 80px;
    width: 280px;
  }
  .login_intro {
    width: 90%;
    padding: 30px 0;
    h1 {
      font-size: 28px;
    }
    p {
      font-size: 13px;
    }
  }
  .form-action-btn {
    > .btn {
      min-width: 120px;
    }
  }
  .form-extra,
    .form-action-btn {
      margin-top: 10px;
  }
  .prelogin-info__title {
    padding: 0 15px;
    font-size: 20px;
  }
  .prelogin-cards {
    margin-top: 20px;
    .card {
      min-height: auto;
      margin-bottom: 10px;
      padding: 10px;
      > img {
        margin-top: 5px;
      }
      &.card-translateY {
        transform: none;

      }
    }
  }
}
@media (max-width: 479.98px) {
  .prelogin-info-col {
    overflow: hidden;
  }
}


/* Number circle */
.circle-number {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  height: 36px;
  min-width: 36px;
  width: 36px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  background-color: rgba(47, 108, 214, 0.10);
  color: #fff;
  z-index: 1;
  top: -2px;
  margin-right: 10px;
  &:before {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 28px;
    height: 28px;
    content: "";
    z-index: -1;
    background-color: #2F6BD6;
    border-radius: 24px;
  }
}
@media(max-width: 575.98px){
  .circle-number{
    font-size: 13px;
    height: 30px;
    min-width: 30px;
    width: 30px;
    &:before{
      width: 22px;
      height: 22px;
    }
  }
}
/*credit card icon*/

.credit-card-icon{
  position: absolute;
  top: 31px;
  right: 7px;
}