.card {
  border: 1px solid #EFEFEF; 
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
  .qd-info-card {
    
  }
}


/* Internet issue */
.internet-issue{
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 60px;
}
.internet-issue-card{
  position: relative;
  max-width: 450px;
  background-color: #353F45;
  color: #fff;
  text-align: center;
  padding: 30px 20px 15px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s linear;
  transform: translate(0,40px);
  img{
    width: 54px;
    height: auto;
    margin: 0 auto;
  }
  .card-title{
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .card-text{
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 2px;
    &:after,
    &:before {
      background-color: #fff;
      width: 20px;
    }
  }
}
@media (max-width: 576px){
  .internet-issue-card{
    max-width: 300px;
    padding: 15px 10px 10px;
    border-radius: 15px;
    img{
      width: 35px;
    }
    .card-body{
      padding: 15px 6px;
    }
    .card-title{
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 5px;
    }
    .card-text{
      font-size: 10px;
      line-height: 16px;
    }
  }
}



/*Data Limit Exceeds*/

.card__data-limit-exceed{
  border-radius: 6px;
  padding: 12px 15px;
  background-color: #FDECEE;
  border: 1px solid #FCC4CB;
  margin-bottom: 15px;
  &.collapsible-layer {
    margin-bottom: 35px;
    &:before {
      content: '';
      width: calc(100% - 20px);
      height: 20px;
      background-color: #FDECEE;
      border: 1px solid #FCC4CB;
      border-radius: 6px;
      position: absolute;
      bottom: -10px;
      left: 10px;
      z-index: -1;
    }
    &:after {
      content: '';
      width: calc(100% - 40px);
      height: 20px;
      background-color: #FDECEE;
      border: 1px solid #FCC4CB;
      border-radius: 6px;
      position: absolute;
      bottom: -20px;
      left: 20px;
      z-index: -2;
    }
    .btn-expand {
      display: block;
    }
  }
  ~ .card__data-limit-exceed {
    display: none;
    animation: notificationFadeIn 0.25s linear;
  }
  .btn-expand {
    min-width: 90px;
    font-size: 12px;
    padding: 6px 16px;
    line-height: 13px;
    border-color: #D7D9DB;
    display: none;
    position: absolute;
    top: -14px;
    right: 15px;
  }
  .data-limit-exceed-detail{
    display: flex;
    align-items: center;
    i{
      margin: 0 5px;
    }
  }
  .data-limit-exceed-info{
    margin-left: 20px;
    h6{
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      margin: 0;
      line-height: 27px;
    }
    p{
      font-size: 16px;
      font-weight: 500;
      color: $color-dark;
      margin: 0;
      line-height: 25px;
    }
  }
  .data-limit-exceed-action{
    flex: 0 0 250px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    i{
      margin-left: 5px;
    }
  }
}
.payment-notification-wrap {
  position: relative;
  z-index: 1;
  &.expand {
    .card__data-limit-exceed {
      &.collapsible-layer {
        margin-bottom: 5px;
        &:before,&:after {
          content: none;
        }
      }
      ~ .card__data-limit-exceed {
        margin-bottom: 5px;
        display: block;
      }
      &:last-child {
        margin-bottom: 15px;
      }
    }
  }
}
@keyframes notificationFadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -10%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.bell{
  display:block;
  width: 32px;
  height: auto;
  font-size: 38px;
  line-height: 10px;
  // margin:50px auto 0;
  color: $brand-primary;
  -webkit-animation: ring 3s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 3s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 3s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}
@media (max-width: 1200px){
  .card__data-limit-exceed{
    padding: 8px 10px;
    .data-limit-exceed-action{
      flex: 0 0 220px;
    }
    .data-limit-exceed-detail{
      i{
        width: 25px;
        margin: 0 10px 0 5px;
        &.bell{
          font-size: 24px;
        }
      }
    }
    .data-limit-exceed-info{
      margin-left: 0;
      h6{
        font-size: 14px;
        line-height: 18px;
      }
      p{
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
@media (max-width: 767.98px){
  .card__data-limit-exceed{
    .data-limit-exceed-action{
      flex: 0 0 550px;
      justify-content: start;
      margin-bottom: 5px;
      padding-left: 42px;
    }
    .data-limit-exceed-detail{
      margin-bottom: 10px;
      i{
        margin-right: 12px;
        &.bell{
          font-size: 28px;
        }
      }
    }
  }
}
@media (max-width: 576px){
  .card__data-limit-exceed{
    .data-limit-exceed-action{
      margin-bottom: 3px;
    }
    .data-limit-exceed-detail{
      margin-bottom: 10px;
      
    }
    .data-limit-exceed-info{
      margin-left: 0;
      h6{
        margin-bottom: 6px;
      }
    }
  }
}