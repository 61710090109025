.qd-grid {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.qd-grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .qd-grid-item .col-md-6{
    &:nth-child(3) .qd-monitor__stat{
      display: none;
    }
  }
}

.qd-aside {
  width: 350px;
  // background-color: #6E6E6E;
  // background-color: #eaebee;
  background-color: #ffffff;
  // background-color: #FF7F50;
  // background-color: lighten( $brand-primary, 3% );
  // color: #fff;
  // -webkit-box-shadow: 0 0 28px 0 rgba(82,63,105,.08);
  // box-shadow: 0 0 28px 0 rgba(82,63,105,.08); 

  border-radius: 10px;
  padding: 30px 25px;
  // border-right: 1px solid #e7e8eb;
  & .nav {
    padding-left: 15px;
    padding-right: 15px;
    >li {
      width: 100%;
      margin-bottom: 2px;
      >a {
        display: block;
        padding: 5px 10px;
        font-weight: 400;
        color: $base-text-color;
        line-height: 18px;
        border-radius: 3px;
        text-decoration: none;
      }
      &:hover,
      &.active {
        > a {
          // background-color: rgba(255, 255, 255, 0.3);
          // background-color: rgba(250, 71, 22, 1);
          background-color: lighten($brand-secondary, 25%);
          color: #fff;
        }
      }
      // &.active {
      //   > a {
      //     background-color: rgba(255, 255, 255, 0.3);
      //   }
      // }
    }
  }
  &.qd-aside-lg {
    width: 350px;
  }
}

.qd-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
    max-width: 100%;
    > .portlet {
      margin: 20px;
    }
}

// Menu list
.qd-toc-link {
  display: block;
  padding: 4px 20px;
  color: $base-text-color;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  .icon {
    font-size: 12px;
    float: right;
    width: 24px;
    height: 24px;
    @include flexbox();
    @include align-items(center);
    @include align-self(center);
    @include justify-content(center);
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transition: -webkit-transform var(--default-subnav-transition);
    transition: -webkit-transform var(--default-subnav-transition);
    transition: transform var(--default-subnav-transition);
    transition: transform var(--default-subnav-transition), -webkit-transform var(--default-subnav-transition);
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
  }
  &:hover {
    color: rgba(250, 71, 22, 0.9);
    text-decoration: none;
  }
}
.qd-toc-item {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .qd-sidenav {
    display: none;
  }
  &.active {
    >.qd-sidenav {
      display: block;
    }
    > .qd-toc-link {
      .icon {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }
}
.qd-section_content {
  border-radius: 10px;
  background-color: #fff;
  min-height: auto;
}
.qd-section-header {
  // background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
  // border-top-right-radius: 10px;
  padding: 0 20px 0 30px;
  min-height: 56px;
  color: $base-text-color;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  .qd-section-header-title {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 18px;
    margin: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    > i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .qd-section-header-action {
    .btn-icon {
      width: 36px;
      height: 36px;
      font-size: 20px;
      padding: 0;
      [class*=" fa-"] {
        margin: 0;
      }
    }
  }
  + .tabbable-line {
    background-color: #fff;
    border-bottom: 2px solid #eff3f5;
    padding: 0 20px;
    color: $base-text-color;
    margin-top: -2px;
  }
}
.qd-section-body {
  padding: 15px;
}

.row-sm {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row-sm > div[class^="col"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
@media (min-width: 992px){
  .qd-section_content{
    min-height: 100%;
  }
}
/* Chart */
.d3js-chart {
  text-align: center;
  .legend {
    width: 100%;
    .key {
      display: inline-block;
      .symbol,
      .name {
        float: left;
      }
      + .key {
        margin-left: 10px;
      }
    }
  }
}

/* | Account | */
/* Profile Info */
.profile-info {
  .profile-avatar-block {
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    border-radius: 6px;
    margin-right: 20px;
    img {
      width: 100px;
      display: block;
      border-radius: 6px;
      border: 1px solid rgba(112, 112, 112, 0.25);
    }
  }
  .profile-avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    color: #464e5f;
    background-color: #f3f6f9;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: .42rem;

    width: 100px;
    height: 100px;
  }
  .avatar-name {
    font-size: 16px;
  }
}

/* Settings page navigation */
.qd-settings-nav {
  .nav {
    padding: 0;
  }
  .qd-sn-item {
    padding: 0;
    display: block;
    list-style: none;
    width: 100%;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .qd-sn-nav-link {
    border-radius: 6px;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    color: #464e5f;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
    &:hover {
      background-color: #f3f6f9;
      color: #0056b3;
    }
    .nav-icon {
      margin-right: 5px;
      width: 24px;
    }
  }
  .active {
    .qd-sn-nav-link {
      background-color: #f3f6f9;
      color: #0056b3
    }
  }
}


/* Avatar */
.qd-avatar {
  position: relative;
  display: inline-block;

  .qd-avatar__holder {
    width: 120px;
    height: 120px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    img {
      max-width: 100%;
      display: block;
    }
  }

  .qd-avatar__upload {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: all .3s;
    transition: all 0.3s;

    input {
      width: 0 !important;
      height: 0 !important;
      overflow: hidden;
      opacity: 0;
    }

    i {
      color: #5d78ff;
      font-size: 0.9rem;
    }
    &:hover {
      -webkit-transition: all .3s;
      transition: all .3s;
      background-color: #5d78ff;
      i {
        color: #fff;
      }
    }
  }

  .qd-avatar__cancel {
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: auto;
    right: -10px;
    bottom: -5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    display: none;

    i {
      -webkit-transition: all .3s;
      transition: all .3s;
      color: #74788d;
      font-size: 0.9rem;
    }
    &:hover i {
      -webkit-transition: all .3s;
      transition: all .3s;
      color: #5d78ff;
    }
  }
  &.qd-avatar--changed .qd-avatar__cancel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  &.qd-avatar--brand .qd-avatar__upload {
    i {
      color: #5d78ff;
    }
    &:hover {
      background-color: #5d78ff;
      i {
        color: #fff;
      }
    }
  }
  &.qd-avatar--light .qd-avatar__upload {
    i {
      color: #fff;
    }
    &:hover {
      background-color: #fff;
      i {
        color: #282a3c;
      }
    }
  }
  &.qd-avatar--dark .qd-avatar__upload {
    i {
      color: #282a3c;
    }
    &:hover {
      background-color: #282a3c;
      i {
        color: #fff;
      }
    }
  }
  &.qd-avatar--primary .qd-avatar__upload {
    i {
      color: #5867dd;
    }
    &:hover {
      background-color: #5867dd;
      i {
        color: #fff;
      }
    }
  }
  &.qd-avatar--success .qd-avatar__upload {
    i {
      color: #0abb87;
    }
    &:hover {
      background-color: #0abb87;
      i {
        color: #fff;
      }
    }
  }
  &.qd-avatar--info .qd-avatar__upload {
    i {
      color: #5578eb;
    }
    &:hover {
      background-color: #5578eb;
      i {
        color: #fff;
      }
    }
  }
  &.qd-avatar--warning .qd-avatar__upload {
    i {
      color: #ffb822;
    }
    &:hover {
      background-color: #ffb822;
      i {
        color: #111;
      }
    }
  }
  &.qd-avatar--danger .qd-avatar__upload {
    i {
      color: #fd397a;
    }
    &:hover {
      background-color: #fd397a;
      i {
        color: #fff;
      }
    }
  }
  &.qd-avatar--circle {
    .qd-avatar__holder {
      border-radius: 50%;
    }

    .qd-avatar__upload {
      right: 0;
      top: 5px;
    }

    .qd-avatar__cancel {
      right: 3px;
      bottom: 10px;
    }
  }
  &.qd-avatar--outline .qd-avatar__holder {
    border: 3px solid #fff;
    -webkit-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 1025px) {
  .qd-form.qd-form--label-right .form-group label:not(.qd-checkbox):not(.qd-radio):not(.qd-option) {
      text-align: right;
  }
}

.email-media {
  img {
    width: 34px;
    height: 34px;
  }
  h5 {
    margin-bottom: 0;
    margin-top: 0;
  }
}

/* Pricing */
.analytices_price_item {
  border: 1px solid #f0f0f6;
  border-radius: 5px;
  padding: 40px;
  cursor: pointer;
  transition: all 0.3s linear;
  .p_head{
      text-align: center;
      border-bottom: 1px solid #ececf6;
      padding-bottom: 30px;
      h5{
          font-size: 20px;
          font-weight: 600;
          color: #2c2c51;
      }
      .rate{
          font-size: 34px;
          font-weight: 600;
          color: red;
          padding-bottom: 13px;
          padding-top: 22px;
      }
      h6{
          font-size: 16px;
          color: #7b7b93;
          font-weight: 400;
      }
      .tag{
          color: #2cc91d;
          i{
              margin-right: 8px;
              vertical-align: middle;
          }
      }
  }
  .p_body{
      padding-top: 20px;
      margin-bottom: 0;
      li{
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          color: #7b7b93;
          font-size: 400;
          align-items: center;
          margin: 12px 0px;
          i{
              font-size: 14px;
          }
      }
  }
  .er_btn{
      margin-top: 40px;
      display: block;
      text-align: center;
  }
  &.active,&:hover{
      box-shadow: 0px 40px 80px 0px rgba(1, 1, 64, 0.08);
      border-color: #fff;
      .p_body{
          li{
              color: #2c2c51;
              .ti-check{
                  color: #26da15;
              }
              .ti-close{
                  color: #fc314e;
              }
          }
      }
  }
  .er_btn_two {
      background: green;
      border-color: green;
      &:hover {
          color: #fff;
          background: red;
          border-color: red;
      }
  }
}
/*============ easy_setup_area css ============*/


/* Plan Table */

.table-plan {
  max-width: 500px;
  th, td {
    padding: 10px;
    font-size: 14px;
  }
  p {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.table-bordered {
    thead {
      td, th {
        border-bottom-width: 1px;
      }
    }
  }
}

.fancy-card {
  // margin-bottom: 15px;
  .card-subtitle {
    font-size: 13px;
    margin-top: 0;
  }
  .badge {
    height: 20px;
    line-height: 16px;
    line-height: normal;
    font-weight: 500;
    color: #797979;
    background-color: #ececec;
    padding: 2px 10px;
    margin-top: -10px;
  }
  .card-title {
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }
  ul {
    font-size: 14px;
    font-weight: 400;
    color: $base-text-color;
    list-style: none;
  }
  strong {
    font-weight: 500;
    color: #000000;
  }
  .payment__due-date{
    font-size: 75%;
    line-height: 16px;
    margin-top: -8px;
    margin-left: 5px;
    font-weight: 500;
    color: #797979;
    white-space: nowrap;
  }
}

.usage-progress {
  margin-top: 20px;
  .progress {
    height: 10px;
    border-radius: 10px;
    .progress-bar {
      border-radius: 10px;
      border-right: 1px solid #fff;
      opacity: 0.75;
    }
  }
}
.usage-counts {
  margin-bottom: 15px;
  label {
    min-width: 200px;
  }
}

// pricing-section
.heading-section {
  text-align: center;
  margin: 40px 0 30px;
  .heading-section-title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 30px;
  }
  p {
    font-size: 16px;
    color: #868e96;
    max-width: 700px;
    margin: 0 auto;
  }
}
.price-section-footer {
  text-align: center;
  max-width: 500px;
  margin: 50px auto 10px;
  p {
    margin-bottom: 0;
    font-size: 18px;
  }
  .btn-block {
    margin-top: 15px;
  }
}

.price_tab {
  border-radius: 45px;
  max-width: 400px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 13px 0px rgba(0, 11, 40, 0.08);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  border: 0px;
  padding: 7px;
  justify-content: center;
  position: relative;

  .nav-item {
      padding: 0px;
      margin: 0px;
      .nav-link {
          margin: 0px;
          font: 400 16px $font-family-base;
          color: #677294;
          padding: 10px 24px;
          display: inline-block;
          border: 0px;
          border-radius: 45px;
          min-width: 190px;
          text-align: center;
          transition: color 0.3s linear;
          position: relative;
          z-index: 1;
          &.active {
              border: 0px;
              border-radius: 45px;
              background: #0021A5;
              color: #fff;
          }
      }
  }
  .hover_bg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: calc(100% - 14px);
      border-radius: 45px;
      z-index: 0;
      transition: all 0.2s linear;
  }
}
@media(max-width: 639.98px){
  .heading-section{
    margin: 25px 0 20px;
    .heading-section-title{
      margin-bottom: 10px;
      font-size: 26px;
    }
    p{
      font-size: 14px;
    }
  }
}

@media(max-width: 575.98px){
  .price_tab{
    display: inline-flex;
    border-radius: 30px;
    margin-bottom: 15px;
    .nav-item{
      display: inline-flex;
    border-radius: 30px;
      .nav-link{
        font-size: 14px;
        min-width: 130px;
        display: inline-block;
      }
    }
  }
}

@media(max-width: 450px){
  .price_tab{
    max-width: 100%;
    .nav-item{
      .nav-link{
        display: block;
        position: relative;
        font-size: 12px;
        padding: 10px 16px;
      }
    }
  }
}

// .price_tab_two {
//   .hover_bg {
//       background: #5f2eed;
//   }
// }

.price_content {
  .fade {
      transform: translateY(10px);
      transition: all 0.3s linear;
      &.show {
          transform: translateY(0px);
      }
  }
  .price_item {
      text-align: center;
      // background-color: rgb(255, 255, 255);
      // box-shadow: 0px 4px 6px 0px rgba(0, 11, 40, 0.1);
      // padding: 50px 50px 40px;
      // border: 1px solid #fff;
      position: relative;
      overflow: hidden;
      transition: all 0.2s linear;
      cursor: pointer;
      .tag {
          position: absolute;
          font: 400 14px $font-family-base;
          color: #fff;
          background: #0021A5;
          padding: 19px 32px;
          top: -10px;
          left: -39px;
          transform: rotate(-45deg);
          span {
              position: relative;
              top: 11px;
          }
      }
      p {
          font-size: 16px;
          line-height: 22px;
          color: #677294;
          font-weight: 300;
          margin-bottom: 0px;
      }
      .price {
          line-height: 40px;
          border-bottom: 1px solid #e9e9f4;
          padding: 33px 0px 30px;
          sub {
              color: #677294;
              bottom: 0;
          }
      }
      .p_list {
          padding: 30px 0px 20px;
          li {
              font: 300 16px/40px $font-family-base;
              color: #505975;
              i {
                  padding-right: 10px;
              }
              .ti-check {
                  color: #0021A5;
              }
              .ti-close {
                  color: #f0002d;
              }
          }
      }
      .price_btn {
          width: 100%;
          border-radius: 30px;
          color: #FA4616;
          border-color: #FA4616;
          padding: 6px 20px;
          &:hover {
              color: #fff;
              background: #FA4616;
          }
      }
      a {
          font-size: 20px;
          color: #FA4616;
          text-decoration: underline;
      }
  }
  .price_icon {
      width: 86px;
      height: 86px;
      opacity: 0.85;
  }
}

/* New Pricing Table */
.pricing-table {
  overflow: hidden;
  max-width: 1170px;
  margin: 20px auto;
  ul{
    width: 100%;
    top: 0px;
    padding: 0;
    z-index: 10;
    background-color: white;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    li{
      list-style: none;
      width: 40%;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      border: 1px solid #ccc;
      margin-left: 0;
      &:nth-child(1){
        background-color: rgba(112, 112, 112, 0.06);
        border-color: rgba(112, 112, 112, 0.2);
      }
      &:nth-child(2){
        margin: 0 5px !important;
        background-color: rgba(0, 33, 165, 0.06);
        border-color: rgba(0, 33, 165, 0.2);
      }
      &:nth-child(3){
        background-color: rgba(250, 71, 22, 0.06);
        border-color: rgba(250, 71, 22, 0.2);
      }
      &.active {
        color: #212529;
        &:nth-child(1){
          background-color: rgba(112, 112, 112, 0.20);
          border-color: rgba(112, 112, 112, 0.2);
        }
        &:nth-child(2){
          margin: 0 5px !important;
          background-color: rgba(0, 33, 165, 0.20);
          border-color: rgba(0, 33, 165, 0.2);
        }
        &:nth-child(3){
          background-color: rgba(250, 71, 22, 0.20);
          border-color: rgba(250, 71, 22, 0.2);
        }
      }
    }
    .price_item{
      .price_icon{
        width: 32px;
        height: 32px;
        margin: 0;
      }
      .price_title{
        font-size: 14px;
        text-decoration: none;
        font-weight: 400;
        padding: 5px;
      }
    }
  }
  table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      th,td{
        border-top: none;
      }
  }
  th {
      display: none;
  }
  td,
  th {
      height: 50px;
      position: relative;
      z-index: 1;
  }
  td,
  th {
      padding: 10px 20px;
      empty-cells: show;
  }
  td,
  th {
      text-align: left;
  }
  td+td,
  th+th {
      text-align: center;
      display: none;
  }
  td.default {
      display: table-cell;
  }
  td {
      font: 400 16px/30px 'Poppins', sans-serif;
      color: #505975;
      position: relative;
      &:before {
          content: '\00a0';
          height: 10000px;
          left: 5%;
          position: absolute;
          top: 0;
          height: 100%;
          width: 90%;
          z-index: -1;
      }
      &:nth-child(1) {
          color: #000;
      }
      &:nth-child(2) {
        &:before {
            background-color: rgba(112, 112, 112, 0.06);
            border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
      &:nth-child(3) {
          &:before {
              background-color: rgba(0, 33, 165, 0.06);
              border-bottom: 1px solid rgba(0, 0, 0, 0.03);
          }
      }
      &:nth-child(4) {
          &:before {
              background-color: rgba(250, 71, 22, 0.06);
              border-bottom: 1px solid rgba(0, 0, 0, 0.03);
          }
      }
      &.no-border {
          &:before {
              border-bottom: none !important;
          }
      }
      strong{
        font-weight: 700;
      }
  }
  tr {
      &:first-child {
          td {
              &:before {
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
              }
          }
      }
      &:last-child {
          td {
              &:before {
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
              }
          }
      }
  }
  .hide {
      border: 0;
      background: none;
  }
  .price {
      line-height: 40px;
      padding-bottom: 10px;
      color: #0021A5;
      font-size: 34px;
      font-weight: 700;
      sub {
          color: #677294;
          bottom: 0;
          font-size: 16px;
          font-weight: 400;
      }
  }
  .price_btn {
      width: 90%;
      margin: 10px auto;
      border-radius: 30px;
      color: #FFF;
      color: #FA4616;
      border: 1px solid #FA4616;
      padding: 6px 40px;
      display: block;
      font-weight: 600;
      &:hover {
          color: #fff;
          background: #FA4616;
          text-decoration: none;
      }
  }
  .price_btn_fill {
      background: #FA4616;
      color: #fff;
      &:hover {
          background: #0021A5;
          border-color: #0021A5;
      }
  }
  .price_table_header {
      .price_item {
          display: none;
      }
  }
  .price_item {
      text-align: center;
      position: relative;
      overflow: hidden;
      transition: all 0.2s linear;
      cursor: pointer;
      .tag {
          position: absolute;
          font: 400 14px $font-family-base;
          color: #fff;
          background: #0021A5;
          padding: 19px 32px;
          top: -10px;
          left: -39px;
          transform: rotate(-45deg);
          span {
              position: relative;
              top: 11px;
          }
      }
      p {
          font-size: 16px;
          line-height: 22px;
          color: #677294;
          font-weight: 300;
          margin-bottom: 0px;
      }
      .price {
          line-height: 40px;
          padding: 20px 0px;
          sub {
              color: #677294;
              bottom: 0;
          }
      }
      .price_title {
          font-size: 24px;
          font-weight: 600;
          margin: 0;
          display: block;
          padding: 10px 0 20px;
          text-decoration: underline;
      }
      .p_list {
          padding: 30px 0px 20px;
          li {
              font: 300 16px/40px $font-family-base;
              color: #505975;
              i {
                  padding-right: 10px;
              }
              .ti-check {
                  color: #0021A5;
              }
              .ti-close {
                  color: #f0002d;
              }
          }
      }
      .price_btn {
          width: 100%;
          border-radius: 30px;
          color: #0021A5;
          border-color: #0021A5;
          padding: 12px 40px;
          &:hover {
              color: #fff;
              background: #0021A5;
          }
      }
  }
  .price_table_empty {
    height: 10px;
    padding: 0;
  }
  .price_icon {
    width: 64px;
    height: 64px;
    opacity: 0.85;
    margin-top: 10px;
  }
  .list-group-horizontal {
    .list-group-item {
      padding: 0;
      & +.list-group-item {
        border-left-width: 1px;
      }
    }
  }
  @media (min-width: 640px) {
      ul {
          display: none;
      }
      td,
      th {
          display: table-cell !important;
      }
      td,
      th {
          width: 280px;
      }
      td+td,
      th+th {
          width: auto;
      }
      .price_table_header {
          .price_item {
              display: block;
          }
      }
  }
  @media (max-width: 1190px) {
      td,
      th {
          width: 200px;
      }
      .price_item {
          .price_title {
              font-size: 18px;
          }
      }
      .price_icon {
          width: 64px;
          height: 64px;
      }
      .price_btn {
          padding: 10px;
      }
  }
  @media (max-width:991px){
      th, td {
          padding: 6px 10px;
      }
      td::after {
          width: 94%;
          left: 3%;
      }
  }
  @media (max-width: 768px){
    .price_item {
      .price_title {
          font-size: 15px;
      }
  }
  }
  @media (max-width:650px){
      td,
      th {
          height: 34px;
          line-height: 22px;
          font-size: 14px;
      }
  }
  @media (max-width:480px){
      td,
      th {
          width: 146px;
          padding: 2px 4px;
      }
      .price {
          font-size: 24px;
      }
      .price_btn {
          font-size: 14px;
      }
  }
}
@media(max-width: 768px){
  .pricing-table {
    .price_btn{
      font-size: 14px;
      line-height: 20px;
    }
  }
  .price_content{
    .price_item{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
@media (max-width: 575.98px) { 
  .pricing-table {
    .price_btn{
      font-size: 12px;
      line-height: 14px;
    }
  }
}

// password hint
.password-hints {
  width: 100%;
  padding-left: 10px;
  font-size: 90%;
  font-weight: 400;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
  > li {
    width: 50%;
    float: left;
    margin-bottom: 6px;
  }
}





/* Static pages responsive Menu */
.qd-grid {
  .qd-aside-overlay {
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    background: 0 0;
  }
  .remove-slide {
    position: absolute;
    top: 25px;
    right: 15px;
    z-index: 1800;
    display: none;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
}
@media (max-width: 991px) {
  .qd-section.ml-4 {
    margin-left: 0 !important;
  }
  .qd-grid {
    .qd-aside {
      &.qd-settings{
        position: fixed;
        left: -360px;
        bottom: 0;
        top: 0;
        transition-duration: 0.2s;
        box-shadow: 0 0 10px #c8c8c8 !important;
        z-index: 110;
        overflow-y: auto;
        border-top-left-radius: 0;
      }
    }
    .qd-aside.qd-settings {
      &.on {
        left: 0;
        z-index: 1500;
        & + .qd-aside-overlay {
          display: block;
          background-color: rgba(190, 184, 184, 0.9);
          z-index: 100;
        }
        & ~ .remove-slide {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .qd-grid {
    .qd-aside {
      width: 300px;
    }
  }
}


// .qd-aside-overlay{
//   display: none;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   position: fixed;
//   background: 0 0;
// }
// @media (max-width:991px){
//   .remove-slide{
//     position: fixed;
//     top: 40px;
//     right: 20px;
//     z-index: 1800;
//     display: none;
    
//   }
//   .qd-aside{
//     border-top-left-radius: 0;
//  }
//  .qd-section.ml-4{
//    margin-left: 0 !important;
//  }
//   .qd-aside {
//     position: fixed;
//     left: -360px;
//     bottom: 0;
//     top: 0;
//     transition-duration: 0.2s;
//     box-shadow: 0 0 10px #c8c8c8 !important;
//     z-index: 110;
//     overflow-y: auto;
//     }
//     .qd-aside{
//       &.on {
//       left: 0;
//       z-index: 1500;
//       &+.qd-aside-overlay{
//         display: block;
//         background-color: rgba(190, 184, 184,0.9);
//         z-index: 100;
//       }
//       &~.remove-slide{
//         display: block;
//       }
//       }
//     }
// }
// @media (max-width: 576px){
//   .qd-aside{
//     width: 300px;
//   }
// }





/*manage-teammates*/
@media (max-width: 650px){
  .qd-table__controls{
    display: block;
  }
  .qd-table__table-controls .table-controls__left-content{
    width: 100%;
  }
  .qd-table__table-controls .table-controls__filter {
    width: 150px;
  }
  .qd-table__table-controls .qd-search-bar {
    width: 150px;
  }
}

/*Payment Reminder*/
.card__payment-reminder{
  margin-top: 20px;
  .card-body{
    padding: 0;
  }
  .payment-plan{
    display: flex;
    align-items: center;
    .icon-box{
      width: 64px;
      height: 64px;
      border: none;
      border-radius: 4px;
      padding: 8px;
      img{
        width: 48px;
        height: auto;
      }
    }
    &__info{
      margin-left: 14px;
      white-space: nowrap;
      h5{
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: rgba($color-dark, 0.75);
        margin-bottom: 8px;
        strong{
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          color: $color-dark;
        }
      }
      p{
        font-size: 16px;
        line-height: 18px;
        color: #B7B7B7;
        margin: 0;
      }
    }
    &__action{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }
    &.payment-plan__starter{
      .icon-box{
        background-color: rgba($brand-secondary, 0.06);
      }
    }
  }
}
@media (max-width: 576px){
  .card__payment-reminder{
    .payment-plan{
      &__action{
        height: auto;
        margin-top: 10px;
        justify-content: flex-start;
      }
    }
  }
}