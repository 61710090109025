/*!
* Modal
*/

.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
  &.in {
    opacity: 1;
    transform: scale(2);
  }
}

/* Vertical Modal */

.modal-vertical {
  height: calc(100% - 0px);
  max-width: 350px;
  margin: 0 0;
  &.modal-lg {
    max-width: 450px
  }
  &.modal-md {
    max-width: 360px;
  }
  .modal-content {
    height: inherit;
    border-width: 0;
    border-radius: 0
  }
  .modal-body {
    height: inherit;
    overflow-y: auto
  }
}

.modal {
  .modal-header {
    background-color: rgba($color-info, 0.15);
    border-color: rgba($color-info, 0.25);
    position: relative;
  }
  .vertical-stepper {
    &.vertical-stepper-sm{
      .vertical-stepper__content{
        .row{
          margin-right: -7px;
          margin-left: -7px;
        }
        [class*="col-"]{
          padding-left: 7px;
          padding-right: 7px;
        }
      }
    }
  }
  &.fullscreen {
    >.modal-dialog {
      .modal-content {
        > .modal-header {
          padding: 0 0 0 30px;
          position: relative;
          height: 70px;
          > .modal-header_content {
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-positive: 1;
            flex-grow: 1;
            height: 100%;
          }
          .modal-fullscreen_title {
            margin: 0 0 0 30px;
          }
          .actions {
            display: flex;
            height: 100%;
            -ms-flex-align: center;
            align-items: center;
            padding-right: 60px;
          }
          .close {
            top: 14px;
          }
        }
      }
    }
    &.show {
      .modal-dialog {
        opacity: 1;
        transform: scale(1);
      }
    }
    .modal-dialog {
      transform: scale(0.9);
      opacity: 0;
      transition: all .15s linear;
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
    .modal-content {
      border: none;
      border-radius: 0;
      margin: 0;
      height: 100%;
    }
  }
  /* Modal Fixed right */
  &.fixed-right {
    padding-right: 0 !important;
    .modal-vertical {
      margin-left: auto
    }
    &.fade {
      .modal-vertical {
        transform: translateX(100%)
      }
    }
    &.show {
      .modal-vertical {
        transform: translateX(0)
      }
    }
  }
}

.modal-body {
  padding: 20px;
}
.modal-header,
.alert {
  // padding-left: 35px;
  // padding-right: 35px;
  padding-left: 20px;
  padding-right: 20px;
  .close {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    margin: 0;
    padding: 6px;
    position: absolute;
    right: 10px;
    top: 11px;
    &:after,
    &:before {
      width: 20px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
      &:after,
      &:before {
        color: #000;
      }
    }
    &.close-sm {
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      padding: 4px;
      &:before,
      &:after {
        width: 16px;
      }
    }
    &.close-lg {
      top: 7px;
      right: 7px;
      width: 44px;
      height: 44px;
      padding: 10px;
      &:before,
      &:after {
        width: 24px;
      }
    }
    > span {
      &:first-child {
        position: absolute;
        top: 0;
        right: 22px;
        font-size: 200%;
        color: #fff;
        text-indent: -99999px;
        &:after{
          content: 'Close';
          position: absolute;
          top: 7px;
          right: 0;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: #969696;
          border-radius: 5px;
          pointer-events: none;
          opacity: 0;
          text-indent: 0;
          transition: all .2s cubic-bezier(.67,.13,.1,.81);
        }
      }
    }
    &:hover {
      > span{
        &:first-child::after{
          right: 20px;
          opacity: 1;
        }
      }
    }
  }

}
/*modal help*/
.model__help{
  .modal-header{
    .close{
      &:hover{
        &>span:first-child::after{
          opacity: 0;
          right: 0;
        }
      }
    }
  }
}
@media (max-width: 575.98px){
  .model__help{
    .modal-vertical{
      &.modal-md{
        max-width: 100%;
      }
    }
  }
}

/* Modal: Upgrade */
.modal__upgrade {
  .modal-header {
    // padding-top: 30px;
    padding-top: 40px;
  }
  .modal-title {
    // font-size: 22px;
    // font-weight: 400;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  &.modal-dialog {
    max-width: 450px;
  }
  .modal-body {
    padding: 30px 30px 15px;
    p {
      margin: 20px 0 0;
      color: rgb(109, 109, 109);
      font-size: 13px;
      line-height: 24px;
    }
  }
  .illustration_upgrade-now {
    max-width: 250px;
    margin-bottom: 15px;
  }
  .modal-footer {
    padding-bottom: 40px;
    .btn{
      font-weight: 600;
    }
  }
}

.backdrop-blur {
  filter:blur(3px)
}

@media(max-width: 575.98px){
  .modal__upgrade {
    .modal-header{
      padding: 30px 15px 10px;
    }
    .modal-title{
      font-size: 16px;
      margin-bottom: 5px;
    }
    p{
      font-size: 12px;
    }
    .modal-body{
      padding: 15px;
      img{
        width: 200px;
      }
    }
    .modal-footer{
      padding-bottom: 30px;
    }
  }
}

/* Help */
.model__help {
  &.show{
    .modal-header{
      .modal-title{
        top: 0;
      }
    }
    .model__help-content{
      margin-top: 0;
      opacity: 1;
      > h4 {
        padding-top: 10px;
        &:first-child {
          padding-top: 0;
        }
      }
    }
    .fixed-model-text{
      top: auto;
      bottom: 0;
    }
  }
  .modal-header{
    position: relative;
    background-image: linear-gradient(-45deg, #4695ff, #1970e2);
    overflow: hidden;
    border-bottom: none;
    border-radius: 0;
    &:before{
      content: '';
      position: absolute;
      right: -6.25rem;
      top: -7.938rem;
      border-radius: 50%;
      height: 12.5rem;
      width: 12.5rem;
      background-image: -webkit-linear-gradient(45deg,#318aff,#247cef);
      background-image: linear-gradient(45deg, #318aff, #247cef);
    }
    &:after{
      content: '';
      position: absolute;
      left: 5.125rem;
      top: 1.188rem;
      border-radius: 50%;
      height: 12.5rem;
      width: 12.5rem;
      background-image: -webkit-linear-gradient(45deg,#318aff,#247cef);
      background-image: linear-gradient(45deg, #318aff, #247cef);
    }
    .modal-title{
      z-index: 1;
      position: relative;
      top: -80px;
      transition: top .7s ease;
      i{
        margin-right: 0.5rem;
      }
      h4{
        font-size: 20px;
        font-weight: 600;
      }
      p{
        font-size: 14px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.75);
      }
    }
    .close{
      position: absolute;
      top: 12px;
      right: 16px;
    }
  }
  .modal-content {
    background-color: #fff;
  }
  .modal-body {
    background-color: #fff;
    transform: translate3d(0,0,0);
    transition: all .25s ease;
    h4{
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    h5{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: $base-text-color;
    }
    p{
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: rgba(66, 66, 66, 0.9);
    }
  }
  .model__help-content{
    transition: all .4s ease 0.3s;
    margin-top: 40px;
    opacity: 0;
  }
  .close {
    &:after,
    &:before {
      background-color: #fff;
    }
  }
  .model__help-list {
    list-style: none;
    counter-reset: item;
    padding-left: 2rem;
    margin-bottom: 1rem;
    li {
      counter-increment: item;
      margin-bottom: 10px;
      color: rgba(66, 66, 66, 0.9);
      font-weight: 500;
      a {
        white-space: nowrap;
      }
      &:before {
        content: counter(item) ".";
        width: 28px;
        height: auto;
        display: inline-block;
        margin-left: -1.75rem;
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 400px;
  }
}

/* Modal Close icons text animation */

/* Modal Delete confirmation */
.modal__alert {
  text-align: center;
  .modal_alert-icon {
    > i {
      font-size: 50px;
    }
    > img {
      width: 64px;
      height: auto;
      margin-bottom: 10px;
    }
    margin: 20px 0 10px;
  }
  .modal_alert-text {
    margin-bottom: 30px;
    h4 {
      font-size: 18px;
      font-weight: 500;
    }
    p {
      max-width: 430px;
      font-size: 14px;
      margin: 0 auto 20px;
      color: $gray-light;
    }
  }
  .modal_alert-btn-group {
    padding: 30px 0 10px;
    border-top: 1px solid $gray-lighter;
    .btn {
      min-width: 120px;
    }
  }
}





/* || Chart Widget || */
$chart_border-color: #e0e3eb;
$chart_btn-hover-color: #f7ecec;

$chart_btn-font-size: 12px;

/* Chart Widget: Base */
.qd-chartWidget {
  border: 1px solid #EAEAEA;
  box-shadow: 0 0 20px rgba(0,0,0,.05);;
  border-radius: 10px;
  position: relative;
  margin-top: 15px;
  background: #fff;
  overflow-y: hidden;
  .qd-chartWidget_top {
    height: 38px;
  }
  .compress-arrows {
    display: none;
  }
  &.chartWidget-fullscreen {
    z-index: 10060;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    .expand-arrows {
      display: none;
    }
    .compress-arrows {
      display: block;
    }
  }

  // &.qd-chartWidget_full-screen {
  //   z-index: 999;
  // }
}
.page-chartWidget-fullscreen {
  overflow: hidden;
}
.qd-cw-content {
  // display: table;
  position: relative;
  width: 100%;
  height: 100%;
  // white-space: nowrap;
}
.qd-cw-wrap {
  display: flex;
  height: 38px;
  flex: 1 1 auto;
  min-width: 0;
}
.qd-chartWidget_body {
  padding: 10px;
  border: 1px solid $chart_border-color;
  border-width: 1px 0 0 0;
}
.qd-chartWidget_overflowY-ctrl{
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 75px;
  z-index: 1;
  background: -moz-linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.87) 50%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.87) 50%, rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.87) 50%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.87) 50%, rgba(255,255,255,1) 100%);
  .btn-light {
    display: flex;
    align-items: center;
    color: #000000;
    background-color: #ffffff;
    border-color: #D0D0D0;
    font-size: 12px;
    padding: 6px 14px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      color: #000000;
      background-color: #ffffff;
      border-color: #D0D0D0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }
    .expand-arrows{
      margin-right: 5px;
    }
  }
}
.qd-chartWidget_bottom {
  .error-status {
    background-color: #EDEDED;
    width: 120px;
    padding: 15px 10px;
    text-align: center;
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.5);
    }
    i {
      font-size: 20px;
      opacity: 0.75;
      margin-bottom: 5px;
    }
  }
}


/* Chart Widget: Top */
.qd-cw-left {
  display: flex;
  margin-right: auto;
  border-right: 1px solid #e0e3eb;
}
.qd-cw-col {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 0 0 auto;
  border-left: 1px solid #e0e3eb;
  border-radius: 0;
  // overflow: hidden;
  min-width: 37px;
  justify-content: center;
  cursor: default;
  &:first-child {
    border: none;
  }
  > div {
    width: 100%;
    height: 100%;
  }
}


.qd-cw_dropdown-wrap {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* Chart Widget: Button */
.qd-cw-btn {
  position: relative;
  justify-content: center;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  height: 100%;
  cursor: default;
  color: #131722;
  z-index: 0;
  transition: background-color .35s ease,color 60ms ease;
  font-size: $chart_btn-font-size;
  padding: 0 10px;

  &:hover {
    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      background-color: $chart_btn-hover-color;
      border-radius: 2px;
    }
  }
}
.qd-cw_dropdown {
  min-width: 140px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  padding: 6px 0;
  margin: 2px 0 0;
  font-size: $chart_btn-font-size;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(107,121,136,.4);
  .qd-cw_dp-item {
    display: block;
    width: 100%;
    padding: 4px 12px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:focus,
    &:hover {
      color: #16181b;
      text-decoration: none;
      background-color: $chart_btn-hover-color;
    }
    &.active,
    &:active {
      color: #000;
      text-decoration: none;
      background-color: #ffd4d4;
    }
    .label {
      padding: 2px 5px;
      text-transform: uppercase;
      margin-left: 5px;
      height: auto;
      &.label-danger {
        font-size: 10px;
        background-color: rgba(246, 78, 95, 0.1);
        color: #F64E60;
      }
    }
  }
  &.qd-cw_dropdown-right {
    left: auto;
    right: 0;
  }
}
.qd-cw-btn-text {
  margin-left: 5px;
}
.open {
  > {
    .qd-cw_dropdown {
      display: block;
    }
  }
}

.qd-chartWidget_body {
  min-height: 300px;
  .qd-cb_body-title {
    font-size: 14px;
    text-align: center;
    color: #505050;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }
}

.qd-chartWidget_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #9598a1;
  border-radius: 2px;
  display: none;
  &.fade {
    opacity: 0;
    transition: opacity .15s linear;
  }
  &.show {
    opacity: .5;
    display: block;
  }
}

.qd-cw-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.qd-cw-modal_dialog {
  max-width: 320px;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  min-height: calc(100% - 3.5rem);
  margin: 1.75rem auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: auto;
  pointer-events: none;
  border-radius: 10px;
  &.modal_dialog-lg {
    max-width: 420px;
  }
}
.qd-cw-modal_content {
  border-radius: 6px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.025);
  outline: 0;
  .modal-header {
    padding: 12px 35px 10px 20px;

  }
}

.fade {
  transition: opacity .15s linear;
}

.payment-method-content {
  .form-group {
    margin-bottom: 10px;
    label {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  .form-control {
    font-size: 13px;
  }
  .pm-form_title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .select2-container--bootstrap4 {
    .select2-selection--single {
      .select2-selection__placeholder {
        font-size: 13px;
        line-height: 1.5;
        color: #c9c9c9;
        font-weight: 300;
      }
    }
  }
}

// Payment Method
.modal__payment-method {
  .modal-footer {
    .btn {
      [class*=" fa-"]{
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
}
.pm_card-added-message {
  text-align: center;
  padding: 20px 0 40px;
  .icon_credit-card {
    margin-bottom: 20px;
  }
  img {
    width: 80px;
    height: 80px;
  }
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 16px;
    color: #8d8c8c;
    font-weight: 400;
  }
  p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .pm_card-type {
    span {
      margin-left: 10px;
      letter-spacing: 1px;
      color: #8d8c8c;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}

.tooltip {
  font-size: 12px;
}
.tooltip-inner {
  background-color: #383838;
  color: #fff;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 18px;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-bottom-color: #383838;
}


// Teammates invitation
// ====================
.member-requests-form {
  .bootstrap-tagsinput {
    min-height: 102px;
    .tag {
      line-height: 14px;
      border: 1px solid rgba(54,153,255,.12);
      background-color: rgba(54,153,255,.05);
      color: #484848;
      border-radius: 24px;
      margin: 4px 0 2px 0;
      [data-role="remove"] {
        &:after,
        &:before {
          height: 1.5px;
          background-color: #3699ff;
        }
      }
    }
  }
}


/* Modal: View */
.card-row-view {
  margin-bottom: 10px;
  box-shadow: 0 2px 3px 0 rgba(192, 189, 189, 0.1), 0 2px 1px -2px rgba(187, 185, 185, 0.1), 0 1px 3px 0 rgba(187, 186, 186, 0.1);
  .card-title {
    margin-bottom: 0;
    text-align: right;
    h5 {
      margin-bottom: 0;
      line-height: 20px;
    }
  }
  .card-body {
    padding: 10px;
  }
  .card-text {
    color: #969696;
  }
}


/* Success message */
.checkmark {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  margin: 10px auto;
  stroke: white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4ed2cc;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4ed2cc;
  fill: none;
  animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4ed2cc;
  }
}





/* Full screen div structure for show more in monitor page */
.qd-fullscreen {
  &.show {
    display: block;
    padding-right: 17px;
    .qd-fs_dialog {
      transform: scale(1);
      opacity: 1;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  .qd-fs_dialog {
    transform: scale(0.9);
    opacity: 0;
    transition: all 0.15s linear;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  .actions {
      display: flex;
      align-items: center;
    .monitor-select-control__control {
      // min-width: 250px;
      max-width: 300px;
      margin: 0 auto;
    }
    .monitor-select-control__menu{
      z-index: 2;
    }
  }
  .qd-fs_content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 0;
    height: 100%;
    pointer-events: auto;
    // background-color: #fff;
    background-color: #F8FAFF;
    background-clip: padding-box;
    outline: 0;
  }
  .qd-fs_header {
    // padding: 15px 0 15px 30px;
    padding: 15px 30px;
    position: relative;
    // height: 150px;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    background-color: #fff;
  }
  .qd-fs_body {
    position: relative;
    padding: 20px;
    height: calc(100% - 70px);
   }
  .qd-fs_header {
    .header_content {
      align-items: center;
      height: auto;
    }
    .header_title {
      margin: 15px 0 15px 0;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #484848;
    }
    .close {
      width: 32px;
      height: 32px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      margin: 0;
      padding: 6px;
      position: absolute;
      right: 12px;
      top: 19px;
      &:before, &:after {
        width: 20px;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.qd-chart-group{
  margin: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  padding-bottom: 20px;
  .qd-chart-group-heading{
    padding: 10px 0 15px;
    .qd-chart-group-title{
      font-size: 18px;
      font-weight: 500;
      color: #2f6bd6;
    }
    p {
      padding-left: 47px;
    }
  }
}
@media (max-width: 1199.98px) {
  .qd-cw-btn-text {
    display: none;
  }
}
@media(max-width: 991px){
  .qd-fs_header{
    .header_content{
      img{
        display: block;
        margin: 0 auto;
      }
    }
  }
  .qd-fullscreen {
    .actions {
      justify-content: center;
      .monitor-select-control__control {
        min-width: 200px;
    }
    }
  }
}
@media(max-width: 767.98px) {
  .qd-cw-btn-text {
    display: none;
  }
}
@media (min-width: 992px) {
  .qd-fullscreen {
    .qd-fs_header {
      display: flex;
      height: 70px;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 0 0 30px;
      .header_content {
        display: flex;
        flex-grow: 1;
        height: 100%;
      }
      .header_title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin: 0;
        // margin: 0 0 0 30px;
      }
    }
    .actions {
      height: 100%;
      padding-right: 60px;
      .monitor-select-control__control{
        min-width: 200px;
      }
    }
  }
}

/*Modal Plan Downgrade*/
.modal{
  .modal-md{
    max-width: 650px;
  }
  .modal-light{
    .modal-header{
      background-color: transparent;
      border: none;
      padding: 35px 40px 0;
      flex-direction: column;
      .close{
        top: 35px;
        right: 32px;
      }
      .modal-header__title{
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        margin: 0;
      }
    }
    .modal-body{
      padding: 25px 40px;
    }
    .modal-footer{
      padding-right: 36px;
      &.modal-footer-warning{
        padding: 12px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        border-top: 1px solid #dee2e6;
        .msg__text{
          font-size: 12px;
          font-weight: 500;
          color: #403D39;
          margin: 0 20px 0 0;
          span {
            font-weight: 600;
            color: #DC3545;
          }
        }
        .btn{
          flex: 1 0 auto;
        }
      }
    }
  }
  /* Modal AB Test */
  &.modal__ab-test {
    .modal-light{
      .modal-body {
        padding: 0;
      }
      .text__user-guide {
        padding: 25px 40px 0;
      }
    }
    form {
      padding: 0;
      > .vertical-stepper__abtest {
        padding: 0 25px;
      }
    }
  }
}

.qd-plan-downgrade{
  font-family: $font-family-poppins;
  color: $color-dark;
  .card__qualdo-edition {
    min-width: 250px;
    margin: 0;
    border-width: 2px;
    overflow: visible;
    .card-body {
      padding: 15px;
    }
    &.your-plan__free{
      &.active{
        border-color: rgba(0, 33, 165, 0.75) !important;
      }
    }
    &.your-plan__starter{
      &.active{
        border-color: rgba(0, 33, 165, 0.75) !important;
      }
      &:before{
        content: none;
      }
      &:after{
        content: none;
      }
    }
    &.your-plan__professional{
      &.active{
        border-color: rgba(250, 70, 22, 0.75) !important;
      }
      &:before{
        content: none;
      }
      &:after{
        content: none;
      }
    }
    &.active{
      &:after{
        content: '';
        position: absolute;
        top: -16px;
        left: -16px;
        width: 32px;
        height: 32px;
        background: url('../../assets/img/icons/icon_check-blue.svg') center center no-repeat;
        background-size: 32px 32px;
      }
    }
  }
  &__card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 30px;
    .qd-plan__benefits{
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin: 14px 0;
      color: $color-dark;
    }
    .qd-plan__time-period{
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      margin: 0 0 5px;
    }
  }
  &__info{
    h4{
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin: 0;
    }
    p{
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      opacity: 0.5;
    }
  }
  .alert-info{
    padding: 15px 20px;
    border-radius: 4px;
    background-color: rgba(228, 230, 239, 0.5);
    h5{
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 4px;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
  }
  &__title{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin: 0 0 10px;
  }
  p{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #484848;
    &.qd-plan-downgrade__desc{
      color: #000;
    }
    strong{
      font-weight: 600;
    }
  }
  .text__user-guide{
    font-size: 12px;
    font-weight: 500;
    max-width: 530px;
    display: inline-block;
    color: #9D9EA5;
    margin: 5px 0 10px;
    span{
      background-color: #F5F5F5;
      border: 1px dashed #C9C5B8;
      border-radius: 3px;
      padding: 4px;
      white-space: nowrap;
    }
  }
  .custom-tag-group{
    margin: 0;
  }
  .alert-warning{
    background-color: #FFF3CD;
    border: 1px dashed #FFC700;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    border-radius: 4px;
    p{
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #664D03;
      text-align: left;
      strong{
        font-weight: 600;
      }
      &+ p{
        padding-top: 8px;
        border-top: 1px solid #F6EAC6;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    .alert-icon_warning{
      width: 24px;
      height: 24px;
      display: inline-block;
      background: url('../../assets/img/icons/icon_exclamation.svg') center center no-repeat;
      background-size: 24px 24px;
      flex-shrink: 0;
    }
  }
}

/*Tabs with arrow right*/
.tabbable-line{
  &.has-arrow-right{
    margin-top: 20px;
    width: 100%;
    &>.nav-tabs{
      &>.nav-item{
        margin: 0 60px 0 0;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          right: -40px;
          top: 9px;
          background: url('../../assets/img/icons/icon_arrow-right.svg') center center no-repeat;
          background-size: 20px 20px;
        }
        &:last-child{
          &:after{
            content: none;
          }
        }
        &>.nav-link{
          padding-left: 2px;
          padding-right: 2px;
          color: #7E8299;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0;
          &.active{
            border-bottom: 2px solid transparent;
            color: #2F6BD6;
            &:after{
              background-color: #2F6BD6;
              border-radius: 4px;
              bottom: -3px;
              height: 2px;
            }
          }
          &:hover{
            color: #377EFD;
            &:after{
              background-color: #377EFD;
              border-radius: 4px;
              bottom: -3px;
              height: 2px;
            }
          }
          &.has-done{
            color: #2F6BD6;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .modal-dialog {
      &.modal-dialog-centered {
          &.modal-dialog-scrollable {
              margin: 20px auto;
              min-height: calc(100% - 40px);
          }
      }
  }
}

@media (min-width: 576px) and (max-width: 768px) { 
  .modal.modal__sm-scrollable-fullscreen{
    .modal-light {
      .modal-content{
        max-width: 570px;
        .modal-header {
          padding: 25px 25px 0;
        }
        .modal-body{
          padding: 20px 25px;
        }
        .modal-footer {
          padding-right: 20px;
          &.modal-footer-warning{
            padding: 12px 25px;
          }
        }
      }
    }
  }
  .qd-plan-downgrade .card__qualdo-edition{
    min-width: 240px;
  }
}

@media (max-width: 767.98px){
  .modal{
    .modal-light {
      .modal-content{
        .modal-header{
          padding: 25px 15px 0;
          .close{
            top: 24px;
            right: 10px;
          }
        }
        .modal-body{
          padding: 20px 15px;
        }
        .modal-footer {
          padding-right: 14px;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .modal{
    .modal-light {
      .modal-content{
        .modal-header{
          .modal-header__title{
            font-size: 20px;
            line-height: 28px;
          }
        }
        .modal-footer{
          &.modal-footer-warning{
            display: block;
            text-align: center;
            padding: 12px 15px;
            .msg__text{
              margin: 0 0 6px 0;
            }
          }
        }
      }
    }
  }
  .qd-plan-downgrade {
    &__title{
      font-size: 18px;
      line-height: 28px;
    }
    &__card{
      display: block;
      padding: 6px 0 20px;
    }
    &__info{
      h4{
        font-size: 18px;
        line-height: 26px;
      }
    }
    .qd-plan__navigation-arrow{
      display: block;
      text-align: center;
      margin: 10px auto;
      img{
        transform: rotate(90deg);
      }
    }
    .alert-info{
      h5{
        font-size: 15px;
        line-height: 24px;
      }
      p{
        font-size: 13px;
      }
    }
    .custom-tag-group{
      .custom-tag{
        padding: 4px 6px 4px 10px;
        margin-right: 4px;
        margin-top: 4px;
      }
    }
    .text__user-guide{
      margin-top: 0;
      span{
        margin: 0;
      }
    }
  }
  .tabbable-line.has-arrow-right {
    margin-top: 15px;
    &>.nav-tabs {
      flex-wrap: nowrap;
      &>.nav-item {
        margin: 0 40px 0 0;
        text-align: center;
        &:after{
          content: none;
        }
      }
    }
  }
  .modal__sm-scrollable-fullscreen{
    .modal-dialog{
      width: 100%;
      height: 100%;
      max-width: none;
      margin: 0;
      &.modal-dialog-centered{
        min-height: 100%;
      }
      &.modal-dialog-scrollable{
        max-height: 100%;
      }
    }
    .modal-content{
      border: 0;
      border-radius: 0;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .qd-plan-downgrade{
    .card__qualdo-edition{
      .card-body{
        .user__plan{
          img{
            width: 36px;
            height: 36px;
          }
          .user__plan-info{
            p{
              font-size: 12px;
              line-height: 18px;
            }
            h4{
              font-size: 18px;
              line-height: 20px;
            }
          }
        }
      }
      &.active{
        &:after{
          top: -12px;
          left: -12px;
        }
      }
    }
  }
}

@media (max-width: 359px){
  .qd-plan-downgrade {
    .text__user-guide{
      line-height: 20px;
    }
  }
}


.text__user-guide{
  font-size: 12px;
  font-weight: 500;
  max-width: 530px;
  display: inline-block;
  color: #9D9EA5;
  margin: 5px 0 10px;
  span{
    background-color: #F5F5F5;
    border: 1px dashed #C9C5B8;
    border-radius: 3px;
    padding: 4px;
    white-space: nowrap;
    i{
      font-size: 14px;
      color: #AFAFAF;
    }
  }
}
