/*deployment*/
.qd-deployment_info {
    margin-bottom: 25px;
    margin-left: 54px;
    .welcome-text {
      font-size: 14px;
      font-weight: 500;
      color: $brand-primary;
      margin: 12px 0;
      display: inline-block;
    }
    h3 {
      font-size: 22px;
      font-weight: 600;
      color: $base-text-color;
      margin-bottom: 8px;
    }
    p {
      color: #898989;
      margin-bottom: 0;
    }
  }
  @media (max-width: 767.98px) {
    .qd-deployment_info {
      margin-left: 40px;
      margin-bottom: 18px;
      .welcome-text {
        font-size: 13px;
        margin: 8px 0;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 575.98px) {
    .qd-deployment_info {
      margin-left: 0;
      margin-bottom: 15px;
      h3 {
        font-size: 18px;
      }
    }
  }
  
  /*qualdo vertical dot steps*/
  .qd-vertical-dot-step {
   .qd-vds__heading {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-start);
      padding: 5px;
    }
    .dot {
      width: 14px;
      height: 14px;
      border-radius: 8px;
      background-color: #BFBFBF;
      border: 3px solid #F9F9FC;
      position: relative;
      margin-right: 0;
    }
    .qd-vds__title {
      font-size: 18px;
      font-weight: 600;
      color: $base-text-color;
      padding-left: 35px;
      margin-bottom: 0;
      line-height: normal;
    }
    .qd-vds__content {
      padding: 20px 15px 15px 42px;
      border-left: 1px solid #E6E6E6;
      margin: -12px 0 -12px 11px;
    }
    .input-group {
      > .input-group-prepend {
        > .input-group-text {
          width: 185px;
          font-size: 14px;
        }
        svg {
          fill: currentColor;
        }
      }
      .select-control__control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      >.form-control {
        &:focus {
          z-index: 0;
        }
      }
      .invalid-feedback, .error-text {
        padding-left: 185px;
      }
      .input-group-icon {
        &> i {
          width: 24px;
          height: 24px;
          top: 10px;
          right: 4px;
          &:hover {
            background-color: #e9ecef;
            color: #999999;
          }
        }
      }
      &:not(.has-validation) {
        &> .form-control:not(:last-child) {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
    .custom-file {
      height: 44px;
      .custom-file-input {
        height: 44px;
      }
      .custom-file-label {
        height: 44px;
        padding: 10px 12px 10px 195px;
        color: #c9c9c9;
        &:after {
          width: 185px;
          height: 42px;
          padding: 10px 12px;
          border-left: none;
          border-right: 1px solid #ced4da;
          right: auto;
          left: 0;
          border-radius: 4px 0 0 4px;
        }
        &:before {
          content: '';
          width: 18px;
          height: 18px;
          position: absolute;
          top: 50%;
          left: 160px;
          z-index: 4;
          transform: translateY(-50%);
          background: url('../../assets/img/icons/icon_folder.svg') center center no-repeat;
          background-size: 18px;
        }
        &.selected {
          color: #495057;
        }
      }
    }
    .form-control {
      height: 44px;
    }
    .select-control__control {
      min-height: 44px !important;
    }
  }
  @media (max-width: 767.98px) {
    .qd-vertical-dot-step {
      .qd-vds__title {
        padding-left: 20px;
        font-size: 16px;
      }
      .qd-vds__content {
        padding-left: 27px;
        padding-top: 18px;
      }
    }
  }
  @media (max-width: 575.98px) {
    .qd-vertical-dot-step {
      .qd-vds__title {
        padding-left: 10px;
        font-size: 15px;
      }
      .qd-vds__heading {
        padding: 5px 0;
      }
      .qd-vds__content {
        margin-left: 6px;
        padding: 15px 0 10px 17px;
      }
      .form-control {
        width: 100%;
        height: 34px;
        max-height: 34px;
      }
      .input-group {
        flex-direction: column;
        > .input-group-prepend {
          > .input-group-text {
            background-color: transparent;
            border: none;
            line-height: normal;
            padding: 0 0 8px;
          }
        }
        .form-control {
          border-radius: 4px;
        }
        .select-control__control {
          min-height: 34px !important;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:not(.has-validation) {
          > .form-control {
            &:not(:last-child) {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
        .invalid-feedback, .error-text {
          padding-left: 0;
        }
        .input-group-icon {
          width: 100%;
          &> i {
            top: 5px;
          }
        }
      }
      .custom-file {
        height: 34px;
        margin-top: 24px;
        .custom-file-input {
          height: 34px;
        }
        .custom-file-label {
          height: 34px;
          padding: 6px 12px;
          &:before {
            top: -30px;
            left: 120px;
            transform: none;
          }
          &:after {
            height: 32px;
            top: -32px;
            padding: 0 0 8px;
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }
  
  /*Wizard*/
  .qd-wizard {
    padding: 10px 24px;
    .card-wizard {
      border: none;
      background-color: transparent;
      box-shadow: none;
      >.card-header {
        background-color: transparent;
        border: none;
        position: relative;
        padding: 30px 20px;
        .description {
          margin: 0;
        }
        &:after {
          content: '';
          width: 90%;
          display: inline-block;
          position: absolute;
          bottom: 0;
          left: 5%;
          height: 1px;
          background-color: #E6E6E6;
        }
      }
      >.card-body {
        padding: 20px 20px 0;
      }
    }
    .card-footer {
      border: none;
      background-color: transparent;
      padding: 20px 32px;
      .btn-next {
        padding-left: 24px;
      }
      .btn-previous,.btn-finish {
        padding-right: 24px;
      }
      .btn-next,.btn-previous,.btn-finish {
        background-color: $brand-primary;
        border-color: $brand-primary;
        border-radius: 25px;
        &:not(:disabled):not(.disabled){
          &:active {
            background-color: $brand-primary;
            border-color: $brand-primary;
          }
        }
      }
      .icon-arrow-back,.icon-arrow-right,.icon-check {
        width: 18px;
        height: 18px;
        display: inline-block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 18px;
        vertical-align: middle;
      }
      .icon-arrow-right {
        background-image: url('../../assets/img/icons/icon_arrow-right-white.svg');
      }
      .icon-arrow-back {
        background-image: url('../../assets/img/icons/icon_arrow-back-white.svg');
      }
      .icon-check {
        background-image: url('../../assets/img/icons/icon_check-circle.svg');
      }
    }
    .nav-pills {
      margin-left: 5%;
      align-items: center;
      justify-content: center;
      counter-reset: counter;
      .nav-item {
        position: relative;
        &:after {
          content: '';
          width: 100%;
          height: 2px;
          background-color: #E4E6EF;
          position: absolute;
          left: 50%;
          top: 17px;
        }
        &:last-child {
          .nav-link {
            padding-right: 0;
          }
          &:after {
            content: none;
          }
        }
      }
      .nav-link {
        color: rgba($gray-base,0.65);
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        padding: 0;
        position: relative;
        z-index: 1;
        .qd-wizard_circle {
          width: 34px;
          height: 34px;
          font-size: 16px;
          font-weight: 600;
          color: #7E8299;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          border-radius: 18px;
          background-color: #E4E6E8;
          border: 4px solid #F9F9FC;
          font-style: normal;
          counter-increment: counter;
          &:before {
            content: counter(counter);
          }
        }
        p {
          margin-bottom: 0;
          background-color: #ffffff;
          padding-top: 8px;
          font-size: 12px;
          font-weight: 500;
          color:rgba(72, 72, 72, 0.5);
          white-space: nowrap;
        }
        &.active {
          background-color: transparent;
          .qd-wizard_circle {
            color: #fff;
            background-color: $color-blue;
          }
          p {
            color: $color-blue;
          }
        }
        &.is-done {
          color: $gray-base;
          .qd-wizard_circle {
            color: #fff;
            background-color: #767676;
            border-color: #E3E3E3;
            &:before {
              content: '';
              width: 16px;
              height: 16px;
              background: url('../../assets/img/icons/icon_check-white.svg') center center no-repeat;
              background-size: 16px;
            }
          }
          p {
            color: $color-dark;
          }
        }
      }
    }
    .qd-payment-success {
      img {
        width: 160px;
        margin-bottom: 24px;
      }
      h2 {
        font-weight: 600;
        margin-bottom: 8px;
      }
      p {
        font-size: 14px;
        color: #868E96;
        font-weight: 500;
        margin-bottom: 30px;
      }
    }
    .heading-section {
      margin: 20px 0 10px;
      +.qd-payment-block {
        border: none;
      }
    }
  }
  @media (max-width: 767.98px) {
    .qd-wizard {
      padding: 10px 15px;
      .card-wizard {
        > .card-header {
          padding: 20px 5px 25px;
          &:after {
            width: 96%;
            left: 2%;
          }
        }
        > .card-body {
          padding: 15px 5px 0;
        }
      }
      .card-footer {
        padding: 15px 20px;
      }
      .nav-pills {
        margin-left: 10px;
        flex-wrap: nowrap;
        .nav-link {
          p {
            white-space: normal;
            word-break: break-word;
            min-height: 44px;
          }
        }
      }
    }
  }
  @media (max-width: 575.98px) {
    .qd-wizard {
      .card-wizard {
        > .card-header {
          padding: 15px 0 20px;
          &:after {
            width: 100%;
            left: 0;
          }
        }
        >.card-body {
          padding: 0;
        }
      }
      .card-footer {
        padding: 10px 12px;
      }
      .nav-pills {
        margin-left: 0;
        .nav-item {
          &:after {
            top: 12px;
          }
        }
        .nav-link {
          p {
            padding-top: 5px;
            font-size: 9px;
          }
          .qd-wizard_circle {
            width: 24px;
            height: 24px;
            font-size: 11px;
            border-radius: 12px;
            font-weight: 500;
            border-width: 3px;
          }
          &.is-done {
            .qd-wizard_circle {
              &:before {
                width: 12px;
                height: 12px;
                background-size: 12px;
              }
            }
          }
        }
      }
      .circle-number {
        font-size: 12px;
        height: 26px;
        min-width: 26px;
        width: 26px;
        margin-right: 5px;
        &:before {
          width: 20px;
          height: 20px;
          top: 3px;
          left: 3px;
        }
      }
      .qd-payment-block {
        .qd-payment-header {
          .qd-payment-title {
            font-size: 15px;
          }
          .dotted-link {
            font-size: 12px;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  /*Progess Bar*/
  .deployment-progress-block {
    padding: 15px 20px 50px;
    .deployment-progress-wrap {
      max-width: 650px;
      margin: 0 auto;
    }
    .deployment-progress-content {
      max-width: 560px;
      margin: 0 auto;
    }
    .icon-arrow-rotate {
      animation: spinner-rotate 3s linear infinite;
    }
  }
  .deployment-progress-status {
    margin-bottom: 10px;
    text-align: center;
    .progress-title-tag {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: #8E8E8E;
      margin-bottom: 8px;
    }
    .progress-title {
      font-size: 24px;
      font-weight: 600;
      color: $base-text-color;
      margin-bottom: 20px;
      &.text-danger {
        animation: blinker 1s linear 5;
      }
    }
    .progress {
      max-width: 450px;
      margin: 0 auto;
      border-radius: 8px;
    }
    .progress-info {
      display: block;
      font-size: 12px;
      font-weight: 500;
      margin-top: 15px;
      color: rgba($base-text-color, 0.65);
      background-color: transparent !important;
    }
  }
  .icon-arrow-rotate {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url('../../assets/img/icons/icon_arrow-rotate.svg') center center no-repeat;
    background-size: 24px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .icon-sync-error {
    width: 28px;
    height: 28px;
    display: inline-block;
    background: url('../../assets/img/icons/icon_sync-error.svg') center center no-repeat;
    background-size: 28px;
    vertical-align: top;
    margin-right: 5px;
  }
  @keyframes spinner-rotate {
    0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media (max-width: 575.98px) {
    .deployment-progress-block {
      padding: 20px 5px 35px;
    }
    .deployment-progress-status {
      .progress {
        max-width: 250px;
      }
      .progress-title-tag {
        font-size: 12px;
      }
      .progress-title {
        font-size: 18px;
        margin-bottom: 15px;
      }
      .progress-info {
        font-size: 11px;
        margin-top: 12px;
      }
    }
    .icon-sync-error {
      width: 24px;
      height: 24px;
      background-size: 24px;
    }
  }
  /*Success message*/
  .deployment-success-msg {
    text-align: center;
    .success-msg__icon {
      width: 44px;
      height: auto;
      margin-bottom: 20px;
    }
    .success-msg__title {
      font-size: 24px;
      font-weight: 600;
      color: $base-text-color;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 575.98px) {
    .deployment-success-msg {
      .success-msg__icon {
        width: 32px;
        margin-bottom: 15px;
      }
      .success-msg__title {
        font-size: 18px
      }
    }
  }
  /* Error msg */
  .deployment-error-msg {
    margin-top: 40px;
    .moniker-alert {
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 6px;
      .alert-heading {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #842029;
      }
      .btn-link {
        font-size: 12px;
        margin-left: 4px;
        text-decoration: underline;
      }
    }
    .collapse-content {
      display: none;
      &.show {
        display: block;
      }
    }
    .highlight {
      padding: 10px 15px;
      margin: 12px 24px;
      background-color: rgba(132, 32, 41, 0.1);
      border-radius: 4px;
      pre {
        font-size: 11px;
        code {
          color: #842029;
        }
      }
    }
  }
  @media (max-width: 575.98px) {
    .deployment-error-msg {
      margin-top: 20px;
      .moniker-alert {
        padding: 15px;
      }
    }
  }
  .deployment-error-list {
    list-style-type: none;
    padding-left: 24px;
    margin: 0;
    li {
      position: relative;
      font-size: 12px;
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        opacity: 0.5;
        position: absolute;
        left: -24px;
        background: url('../../assets/img/icons/icon_arrow-pointer.svg') center center no-repeat;
        background-size: 16px;
      }
      &+ li {
        margin-top: 10px;
      }
    }
  }
  /*card Link*/
  .qd-link-card {
    @include flex-direction(row);
    @include align-items(center);
    background-color: #F7F7F7;
    border: none;
    padding: 5px;
    white-space: nowrap;
    .card-icon {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      width: 28px;
      height: 28px;
      padding: 2px;
      border-radius: 6px;
      background-color: #ffffff;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
      img {
        display: block;
      }
    }
    .card-link {
      padding-left: 10px;
      font-weight: 500;
      color: #007BFF;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  /*alert success*/
  .prelogin-alert-success {
    padding: 15px;
    max-width: 380px;
    border-radius: 6px;
    .alert-heading {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 6px;
      line-height: normal;
      [class*='fa-'] {
        font-size: 16px;
        margin-right: 6px;
      }
    }
    p {
      margin-bottom: 0;
      line-height: normal;
    }
    .close {
      justify-content: center;
      width: 24px;
      height: 24px;
      padding: 4px;
      &:before,&:after {
        width: 16px;
        background-color: #484848;
      }
      > span {
        display: none;
      }
    }
  }
  
/*Billing Price Estimation*/
.billing-price-estimation {
  padding-top: 15px;
  .card {
    border-color: $color-blue;
  }
  .card-body {
    padding-top: 30px;
  }
  .badge {
    font-size: 16px;
    font-weight: 400;
    min-height: 30px;
    background-color: #fff;
    border: 1px solid $color-blue;
    line-height: normal;
    padding: 3px 12px;
    color: $color-blue;
    border-radius: 6px;
    position: absolute;
    top: -15px;
    left: 20px;
    sup {
      font-size: 6px;
      margin-left: 2px;
    }
  }
}
.billing-user-guide {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    font-size: 12px;
    color: rgba($color-dark, 0.5);
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    [class*= "fa-"] {
      margin-right: 8px;
      font-size: 10px;
      vertical-align: baseline;
      color: #D0D0D0;
    }
  }
}
@media (max-width: 575.98px) {
  .billing-price-estimation {
    .badge {
      font-size: 14px;
      min-height: 26px;
      top: -13px;
      left: 15px;
    }
    .card-body {
      padding: 20px 10px 15px;
    }
  }
}

/*Payment Profile*/
.payment-profile-card {
  // max-width: 300px;
  display: flex;
  color: #6C757D;
  padding: 10px 20px;
  margin-bottom: 10px;
  .card-icon {
    font-size: 16px;
  }
  .card-content {
    padding-left: 14px;
  }
  .card-title,.card-value {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }
  .card-value {
    color: $color-dark;
    margin-bottom: 0;
    line-height: 24px;
  }
}

/*Plan Usage Accordion*/
.plan-usage-accordion {
  margin-bottom: 15px;
  >.card {
    box-shadow: 0 0 20px rgba(82, 63, 105, 0.05);
    .card-header {
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      padding: 20px;
      background-color: transparent;
      border-color: #EFEFEF;
      .cloud-deployment-card {
        .card-stacked {
          margin-left: 15px;
        }
        .card-icon {
          width: 48px;
          height: 48px;
          @include flexbox;
          @include align-items(center);
          @include justify-content(center);
          img {
            width: 36px;
          }
        }
        .card-text {
          font-size: 14px;
          strong {
            font-weight: 600;
          }
        }
      }
    }
  }
  .fancy-card {
    border: none;
    box-shadow: none;
    background-color: transparent;
    .card-body {
      padding: 0;
    }
    .card-subtitle {
      margin-top: 0;
    }
    .badge {
      margin-top: 0;
    }
  }
  &.expand {
    >.card {
      .card-header {
        .card-action {
          .btn i{
            display: inline-block;
            transform: rotate(90deg);
            animation: rotateChevron 0.2s linear;
          }
        }
      }
    }
  }
}
@keyframes rotateChevron {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}

/*Promocode*/
.promocode-block {
  .promocode-text {
    font-size: 12px;
    color: #007BFF;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .promocode-form {
    display: none;
    .form-control,.btn {
      height: 34px;
    }
    .btn {
      line-height: 16px;
    }
    &.show {
      display: flex;
      align-items: center;
    }
    .invalid-feedback {
      color: #F64E60;
      font-size: 12px;
      width: 100%;
      margin-top: .25rem;
    }
  }
  .invalid-feedback {
    color: #F64E60;
    font-size: 12px;
    width: 100%;
    margin-top: .25rem;
    display: flex;
  }
  label {
    font-size: 14px;
    font-weight: 400;
  }
  .promocode-success-text {
    font-size: 12px;
    color: #28a745;
    display: none;
    i {
      cursor: pointer;
    }
  }
}

/* Modal Payment Renew Alert */
.modal_payRenew-alert {
  text-align: center;
  .payRenew-alert_icon {
    margin: 40px 0 20px;
    img {
      width: 56px;
      height: auto;
    }
  }
  .payRenew-alert_text {
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: $color-dark;
      margin-bottom: 20px;
      line-height: normal;
      strong {
        font-weight: bold;
      }
    }
    p {
      max-width: 430px;
      color: #6C757D;
      margin: 0 auto;
      strong {
        font-weight: 600;
      }
    }
  }
  .payRenew-alert_action {
    padding: 40px 0;
  }
}
.icon-cross {
  position: absolute;
  right: 20px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  opacity: 0.5;
  transform: scale(1);
  &:before,&:after {
    content: '';
    position: absolute;
    left: 12px;
    top: 4px;
    width: 2px;
    height: 18px;
    background-color: #000;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    opacity: 0.75;
    transform: scale(1.2);
  }
}
@media (max-width: 575.98px) {
  .modal_payRenew-alert {
    .payRenew-alert_icon {
      margin-top: 30px;
      margin-bottom: 15px;
      img {
        width: 48px;
      }
    }
    .payRenew-alert_text {
      h4 {
        font-size: 18px;
        margin-bottom: 15px;
      }
      p {
        font-size: 13px;
      }
    }
    .payRenew-alert_action {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

/*Modal delete confirmation*/
.modal_confirmation-alert {
  text-align: center;
  padding: 50px 30px;
  .confirmation-alert_img {
    width: 150px;
    height: auto;
    margin: 0 auto 35px;
    img {
      width: 100%;
    }
  }
  .confirmation-alert_text {
    margin-bottom: 40px;
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: $color-dark;
      margin-bottom: 15px;
      line-height: normal;
    }
    p {
      color: #6C757D;
      margin-bottom: 0;
      line-height: normal;
      strong {
        color: #464E5F;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }
  .confirmation-alert_form {
    // margin-bottom: 50px;
    .icon-input-group {
      > .form-control {
        height: 40px;
        border-radius: 25px;
      }
    }
  }
  .moniker-alert {
    text-align: left;  
    padding: 10px 15px;
  }
  .confirmation-alert_action {
    margin-top: 30px;
  }
}

@media (max-width: 575.98px) { 
  .modal_confirmation-alert {
    padding: 40px 20px;
    .confirmation-alert_img {
      width: 130px;
      margin-bottom: 25px;
    }
    .confirmation-alert_text {
      margin-bottom: 30px;
      h4 {
        font-size: 18px;
        margin-bottom: 12px;
      }
      p {
        font-size: 13px;
      }
    }
    .confirmation-alert_form {
      margin-bottom: 40px;
    }
  }
}
