/* Components */

/* progress bar */
.data-progress-status {
  max-width: 600px;
  margin: 90px auto;
  h2 {
    font-family: arial;
    color: #484848;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {
    font-family: arial;
    color: #484848;
    margin-bottom: 30px; 
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 20px;
    &.progress-percentage {
      font-size: 18px;
      color: #6d6d6d;
      strong {
        font-weight: 600;
        font-size: 24px;
        small {
          font-size: 75%;
        }
      }
    }
  }
  .progress {
    max-width: 400px;
    margin: 0 auto 20px;
    background: 50%;
    border-radius: 100px;
    align-items: flex-start;
    display: flex;
    height: 40px;
    padding: 4px;
    box-shadow: 0px 0px 10px 0px #969696;
  }
  .progress-bar {
    border-radius: 100px;
    background: rgb(252, 176, 69);
    background: linear-gradient(90deg, rgba(252, 176, 69, 1) 0%, rgba(250, 70, 22, 1) 50%, rgba(253, 29, 29, 1) 100%);
    height: 32px;
    animation: 3s normal forwards;
    width: 60%;
  }
}

/* Animated Striped Proress */
.animated-striped-progress {
  background-image: linear-gradient(-45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 2rem 2rem;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-animation: 1s linear infinite striped-progress;
  animation: 1s linear infinite striped-progress;
}
@-webkit-keyframes striped-progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 2rem 0;
  } 
}
@keyframes striped-progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 2rem 0;
  } 
}