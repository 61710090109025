//
// Textual form controls
//

/* | error text | */
  .error-text {
    color: #F64E60;
    font-size: 12px;
    width: 100%;
    margin-top: .25rem;
  }

.dropdown-error-text{
  color: #F64E60;
  font-size: 12px;
}

.form-control {
  font-size: 14px;
  font-family: 'Poppins', sans-serif; 
  font-weight: 400;
  // color: $input-color;
  // border-color: $input-border-color;
  // border-radius: 20px;  
  height: 34px;
  &:focus {
    // color: $input-focus-color;
    // background-color: $input-focus-bg;
    // border-color: $input-focus-border-color;
    // outline: 0;
    box-shadow: none;
  }
  // Placeholder
  &::placeholder {
    color: #c9c9c9;
    font-family: 'Poppins', sans-serif; 
  }
}

input[type="tel"]::placeholder{
  font-size: 14px;
  font-family: 'Poppins', sans-serif; 
  font-weight: 400;
}
input.number::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: pink;
}
input.number::-moz-placeholder { /* Firefox 19+ */
  color: pink;
}
input.number:-ms-input-placeholder { /* IE 10+ */
  color: pink;
}
input.number:-moz-placeholder { /* Firefox 18- */
  color: pink;
}
.icon-input-group {
  position: relative;
  > i {
    position: absolute;
    left: 14px;
    top: 8px;
    color: #ccc;

    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    
    &.security-eye {
      left: auto;
      right: 14px;
      cursor: pointer;
    }
  }
  > .form-control {
    padding-left: 40px;
  }
  // .form-control:not(.m-0) + .error {
  //   margin-top: -14px;
  //   display: block;
  // }
  .form-control:not(.m-0) + .invalid-feedback {
    // margin-top: -14px;
    display: block;
  }
}
.form-group {
  // .error {
  //   font-size: 12px;
  // }
  .invalid-feedback {
    font-size: 12px;
  }
  label {
    font-size: 14px;
    font-weight: 400;
  }
}
.custom-control {
  line-height: 24px;
}

/* Form Validation */
.was-validated .form-control:invalid,
.was-validated .form-control:valid,
.form-control.is-invalid {
  background-image: none;
  padding-right: 0.75rem;
  border-color: $color-danger;
  &:focus{
    border-color: $color-danger;
    box-shadow: 0 0 0 .2rem rgba($color-danger,.25);
  }
}


.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: $color-success;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='rgb(78, 210, 204)' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  &:focus{
    border-color: $color-success;
    box-shadow: 0 0 0 .2rem rgba($color-success,.25);
  }
}







.checkbox label, label.checkbox-inline {
  position: relative;
  padding-left: 1.5625rem;
  font-size: 14px;
}
.checkbox label .checkbox-decorator, label.checkbox-inline .checkbox-decorator {
  position: absolute;
  left: 0;
  padding: .7em;
  margin: -.7em;
  line-height: .7;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 100%;
}
.checkbox label .checkbox-decorator .check, label.checkbox-inline .checkbox-decorator .check {
  position: relative;
  // z-index: 1;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
  border: 0.125rem solid rgba(0, 0, 0, 0.54);
  border-radius: 0.125rem;
}
.checkbox label .checkbox-decorator .check::before, label.checkbox-inline .checkbox-decorator .check::before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  margin-top: -4px;
  margin-left: 6px;
  color: #757575;
  content: "";
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  transform: rotate(45deg);
  animation: checkbox-off;
}
.is-focused {
  .checkbox label .checkbox-decorator .check::before, label.checkbox-inline .checkbox-decorator .check::before {
    animation: checkbox-off 0.3s forwards;
  }
}
.checkbox label input[type=checkbox], label.checkbox-inline input[type=checkbox] {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 0;
  height: 0;
  margin: 0;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
}
.checkbox label input[type=checkbox]:focus + .checkbox-decorator .check::after, label.checkbox-inline input[type=checkbox]:focus + .checkbox-decorator .check::after {
  opacity: 0.2;
}
.checkbox label input[type=checkbox]:checked + .checkbox-decorator .check, label.checkbox-inline input[type=checkbox]:checked + .checkbox-decorator .check {
  color: #3F51B5;
  border-color: #3F51B5;
}
.checkbox label input[type=checkbox]:checked + .checkbox-decorator .check::before, label.checkbox-inline input[type=checkbox]:checked + .checkbox-decorator .check::before {
  color: #3F51B5;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 032px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  animation: checkbox-on 0.3s forwards;
}
.checkbox label input[type=checkbox][disabled] {
  + .checkbox-decorator .check::after, .check::after {
    background-color: rgba(0, 0, 0, 0.87);
    transform: rotate(-45deg);
  }
}
fieldset[disabled] .checkbox label input[type=checkbox] {
  + .checkbox-decorator .check::after, .check::after {
    background-color: rgba(0, 0, 0, 0.87);
    transform: rotate(-45deg);
  }
}
label.checkbox-inline input[type=checkbox][disabled] {
  + .checkbox-decorator .check::after, .check::after {
    background-color: rgba(0, 0, 0, 0.87);
    transform: rotate(-45deg);
  }
}
fieldset[disabled] label.checkbox-inline input[type=checkbox] {
  + .checkbox-decorator .check::after, .check::after {
    background-color: rgba(0, 0, 0, 0.87);
    transform: rotate(-45deg);
  }
}
.checkbox label input[type=checkbox][disabled] {
  + .checkbox-decorator .check, .check {
    border-color: rgba(0, 0, 0, 0.26);
  }
}
fieldset[disabled] .checkbox label input[type=checkbox] {
  + .checkbox-decorator .check, .check {
    border-color: rgba(0, 0, 0, 0.26);
  }
}
label.checkbox-inline input[type=checkbox][disabled] {
  + .checkbox-decorator .check, .check {
    border-color: rgba(0, 0, 0, 0.26);
  }
}
fieldset[disabled] label.checkbox-inline input[type=checkbox] {
  + .checkbox-decorator .check, .check {
    border-color: rgba(0, 0, 0, 0.26);
  }
}
.checkbox label input[type=checkbox][disabled] + .checkbox-decorator .ripple-container, fieldset[disabled] .checkbox label input[type=checkbox] + .checkbox-decorator .ripple-container, label.checkbox-inline input[type=checkbox][disabled] + .checkbox-decorator .ripple-container, fieldset[disabled] label.checkbox-inline input[type=checkbox] + .checkbox-decorator .ripple-container {
  display: none;
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px; }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px; }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px; } }
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset; }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset; }
  50% {
    width: 0;
    height: 0;
    margin-top: -4px;
    margin-left: 6px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    transform: rotate(45deg); }
  51% {
    width: 20px;
    height: 20px;
    margin-top: -2px;
    margin-left: -2px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
    transform: rotate(0deg); }
  100% {
    width: 20px;
    height: 20px;
    margin-top: -2px;
    margin-left: -2px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    transform: rotate(0deg); } }

@media (min-width: 768px) {
  .form-label-right-md {
    .col-form-label {
      text-align: right;
    }
  }
}


//
// Swtich style
//


input[type=checkbox],
input[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0
}
.switch {
  display: inline-block;
  font-size: 1rem;
  input {
    &:empty {
      margin-left: -999px;
      height: 0;
      width: 0;
      overflow: hidden;
      position: absolute;
      opacity: 0;
      ~ span {
        display: inline-block;
        position: relative;
        float: left;
        width: 1px;
        text-indent: 0;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:after, &:before {
          position: absolute;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          top: 0;
          bottom: 0;
          left: 0;
          content: ' ';
          -webkit-transition: all .1s ease-in;
          transition: all 0.1s ease-in;
        }
      }
    }
    &[disabled] {
      cursor: not-allowed;
      ~ span {
        &:after, &:before {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }
  &.switch-icon input:checked ~ span:after {
    font-family: Ki;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "";
    line-height: 0;
  }
  input {
    &:empty ~ span {
      margin: 2px 0;
      height: 30px;
      width: 57px;
      border-radius: 15px;
      &:after, &:before {
        width: 54px;
        border-radius: 15px;
      }
      &:after {
        height: 24px;
        width: 24px;
        top: 3px;
        bottom: 3px;
        margin-left: 3px;
        font-size: .65em;
        text-align: center;
        vertical-align: middle;
      }
    }
    &:checked ~ span:after {
      margin-left: 26px;
    }
  }
  &.switch-xs {
    height: 20px;
    input {
      &:empty ~ span {
        margin: 2px 0;
        height: 20px;
        width: 40px;
        border-radius: 12px;
        &:after, &:before {
          width: 38px;
          border-radius: 12px;
        }
        &:after {
          height: 16px;
          width: 16px;
          top: 2px;
          bottom: 2px;
          margin-left: 2px;
          font-size: .55em;
          text-align: center;
          vertical-align: middle;
        }
      }
      &:checked ~ span:after {
        margin-left: 20px;
      }
    }
  }
  &.switch-sm input {
    &:empty ~ span {
      margin: 2px 0;
      height: 24px;
      width: 40px;
      border-radius: 12px;
      &:after, &:before {
        width: 38px;
        border-radius: 12px;
      }
      &:after {
        height: 20px;
        width: 20px;
        top: 2px;
        bottom: 2px;
        margin-left: 2px;
        font-size: .55em;
        text-align: center;
        vertical-align: middle;
      }
    }
    &:checked ~ span:after {
      margin-left: 16px;
    }
  }
  &.switch-lg input {
    &:empty ~ span {
      margin: 2px 0;
      height: 40px;
      width: 75px;
      border-radius: 20px;
      &:after, &:before {
        width: 72px;
        border-radius: 20px;
      }
      &:after {
        height: 34px;
        width: 34px;
        top: 3px;
        bottom: 3px;
        margin-left: 3px;
        font-size: .75em;
        text-align: center;
        vertical-align: middle;
      }
    }
    &:checked ~ span:after {
      margin-left: 34px;
    }
  }
}

.form-group.row .switch {
  margin-top: 0.15rem;
  &.switch-sm {
    margin-top: 0.3rem;
  }
  &.switch-lg {
    margin-top: 0;
    position: relative;
    top: -0.3rem;
  }
}

.switch {
  input {
    &:empty ~ span {
      &:before {
        background-color: #ecf0f3;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #ecf0f3;
      }
      &:after {
        opacity: 1;
        color: #fff;
        background-color: #8950fc;
      }
    }
  }
  &.switch-primary:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #8950fc;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #8950fc;
      }
      &:after {
        opacity: 1;
        color: #8950fc;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-primary input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #8950fc;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #8950fc;
      }
    }
  }
  &.switch-secondary:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #e5eaee;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #e5eaee;
      }
      &:after {
        opacity: 1;
        color: #e5eaee;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-secondary input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #e5eaee;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #e5eaee;
      }
    }
  }
  &.switch-success:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #1bc5bd;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #1bc5bd;
      }
      &:after {
        opacity: 1;
        color: #1bc5bd;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-success input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #1bc5bd;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #1bc5bd;
      }
    }
  }
  &.switch-info:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #767a83;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #6993ff;
      }
      &:after {
        opacity: 1;
        color: #6993ff;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-info input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #6993ff;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #6993ff;
      }
    }
  }
  &.switch-warning:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #ffa800;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #ffa800;
      }
      &:after {
        opacity: 1;
        color: #ffa800;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-warning input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #ffa800;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #ffa800;
      }
    }
  }
  &.switch-danger:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #f64e60;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #f64e60;
      }
      &:after {
        opacity: 1;
        color: #f64e60;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-danger input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #f64e60;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #f64e60;
      }
    }
  }
  &.switch-light:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #f3f6f9;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #f3f6f9;
      }
      &:after {
        opacity: 1;
        color: #f3f6f9;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-light input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #f3f6f9;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #f3f6f9;
      }
    }
  }
  &.switch-dark:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #212121;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #212121;
      }
      &:after {
        opacity: 1;
        color: #212121;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-dark input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #212121;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #212121;
      }
    }
  }
  &.switch-white:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #fff;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #fff;
      }
      &:after {
        opacity: 1;
        color: #fff;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-white input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #fff;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #fff;
      }
    }
  }
  &.switch-dark-75:not(.switch-outline) input {
    &:empty ~ span {
      &:before {
        background-color: #a4a4a4;
      }
      &:after {
        background-color: #fff;
        opacity: 0.7;
      }
    }
    &:checked ~ span {
      &:before {
        background-color: #a4a4a4;
      }
      &:after {
        opacity: 1;
        color: #a4a4a4;
        background-color: #fff;
      }
    }
  }
  &.switch-outline.switch-dark-75 input {
    &:empty ~ span {
      &:before {
        border: 2px solid #ecf0f3;
        background-color: transparent;
      }
      &:after {
        background-color: #ecf0f3;
      }
    }
    &:checked ~ span {
      &:before {
        border: 2px solid #464e5f;
        background-color: transparent;
      }
      &:after {
        color: #fff;
        background-color: #464e5f;
      }
    }
  }
}

//
// Select2DropDown
//

.select2-container--bootstrap4 {
  .select2-selection--multiple,
  .select2-selection--single {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    // border: 1px solid #e5eaee;
    outline: 0 !important;
    // border-radius: .42rem;
    height: auto;
    line-height: 0;
  }
  .select2-selection--multiple .select2-selection__placeholder,
  .select2-selection--single .select2-selection__placeholder {
    color: #b5b5c3;
  }

  // &.select2-container--focus {
  //   .select2-selection--multiple, .select2-selection--single {
  //     border-color: #ab82fd;
  //   }
  // }

  // &.select2-container--open {
  //   .select2-selection--multiple, .select2-selection--single {
  //     border-color: #ab82fd;
  //   }
  // }
  .select2-selection--multiple .select2-selection__arrow,
  .select2-selection--single .select2-selection__arrow {
    border: 0;
    margin-left: .5rem;
    font-size: .85rem;
    left: auto;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: static;
    width: auto;
    height: 20px;
    margin-right: 1rem;
  }
  .select2-selection--multiple .select2-selection__arrow:after,
  .select2-selection--single .select2-selection__arrow:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "\f078";
    font-size: .6rem;
    color: #80808f;
  }
  .select2-selection--multiple .select2-selection__arrow b {
    display: none;
  }
  .select2-selection--single {
    .select2-selection__arrow b {
      display: none;
    }

    .select2-selection__rendered {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 100%;
      color: #464e5f;
      position: relative;
      padding: .65rem 3rem .65rem 1rem;
      line-height: 1.5;

      .select2-selection__clear {
        border: 0;
        font-size: 0;
        display: inline-block;
        right: 1.85rem;
        margin-right: .5rem;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        position: absolute;
        right: 0;

        &:after {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          text-transform: none;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          content: "";
          font-size: .6rem;
          color: #80808f;
        }
      }
    }
  }
  .select2-selection--multiple .select2-selection__rendered {
    color: #464e5f;
    padding: 4px 8px;
    line-height: 1.5;

    .select2-selection__choice {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 4px 8px;
      font-size: 12px;
      margin: 2px 6px 2px 0;
      position: relative;
      float: left;
      color: #464e5f;
      background: #f3f6f9;
      border-color: rgb(240, 240, 240);
      border-radius: 3px;

      .select2-selection__choice__remove {
        font-size: 0;
        display: inline-block;
        line-height: 0;
        margin-right: 4px;
        position: relative;

        &:before {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          text-transform: none;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          content: "\f00d";
          font-size: 12px;
          color: #80808f;
        }
      }
    }

    .select2-search__field {
      &::-moz-placeholder {
        color: #b5b5c3;
        opacity: 1;
      }

      &:-ms-input-placeholder, &::-webkit-input-placeholder {
        color: #b5b5c3;
      }
    }
  }
  .select2-search--dropdown {
    padding: 12px 10px;

    // .select2-search__field {
    //   outline: 0 !important;
    //   border-radius: .42rem;
    //   border: 1px solid #e5eaee;
    //   padding: 0.65rem 1rem;
    // }
  }
  // .select2-results__options {
  //   padding: 0.65rem 0;
  // }
  .select2-results__option {
    // padding: 0.65rem 1rem;

    &[aria-disabled=true] {
      cursor: not-allowed;
    }

    .select2-results__group {
      // padding: 5px 15px;
      font-weight: 600;
    }
  }
  .select2-container .select2-search--inline .select2-search__field {
    margin: 0;
  }
  .select2-container .select2-search--inline .select2-search__field {
    margin-top: 0;
  }
  .select2-dropdown {
    border: 1px solid #e5eaee;
    -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  }
  .select2-results__option {
    &.select2-results__option--highlighted {
      background: #f3f6f9;
      color: #464e5f;
    }

    &[aria-disabled=true] {
      color: #464e5f;
      opacity: 0.6;
    }

    &[aria-selected=true] {
      background: #ecf0f3;
      color: #464e5f;
    }
  }
  &.select2-container--disabled {
    cursor: not-allowed;

    .select2-selection--multiple, .select2-selection--single {
      cursor: not-allowed;
      background-color: #fff;
      opacity: 0.5;
    }
  }
}

select.select2 {
  opacity: 0;
}

.select2 {
  > select.form-control {
    opacity: 0;
  }

  &.select2--elevate .select2-container--default {
    .select2-selection--multiple, .select2-selection--single {
      -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    }
  }

  &.select2--solid .select2-container--default {
    .select2-selection--multiple, .select2-selection--single {
      background-color: #f3f6f9;
      border-color: #ecf0f3;
    }

    .select2-selection--multiple .select2-selection__placeholder, .select2-selection--single .select2-selection__placeholder {
      color: #b5b5c3;
    }
  }
}

.form.form-state {
  .is-valid .select2-container--default {
    .select2-selection--multiple, .select2-selection--single {
      border-color: success;
    }

    &.select2-container--focus {
      .select2-selection--multiple, .select2-selection--single {
        border-color: success;
      }
    }

    &.select2-container--open {
      .select2-selection--multiple, .select2-selection--single {
        border-color: success;
      }
    }
  }
  .is-invalid .select2-container--default {
    .select2-selection--multiple, .select2-selection--single {
      border-color: danger;
    }

    &.select2-container--focus {
      .select2-selection--multiple, .select2-selection--single {
        border-color: danger;
      }
    }

    &.select2-container--open {
      .select2-selection--multiple, .select2-selection--single {
        border-color: danger;
      }
    }
  }
}


// Form
.qd-search-bar {
  .input-group {
    border-radius: 4px;
    height: 34px;
    .btn {
      padding: 5px 0 5px 10px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.3);
      text-decoration: none;
    }
  }
  .form-control{
    border: none;
    background-color: transparent;
    height: auto;
    padding: 6px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}



.single-row-form{
  flex-shrink: 0;
  .btn{
    margin-left: 15px;
  }
}
@media (max-width: 576px){
  .single-row-form{
    flex-shrink: 1;
    .form-group{
      width: 100%;
    }
    .btn{
      display: block;
      width: 100%;
      margin-left: 0;
    }
  }
}


/* Custom Switch */

.custom-switch {
  line-height: 32px;
  padding-left: 48px;
  .custom-control-label {
    &:before {
      left: calc(-48px + 2px);
      width: 44px;
      height: 24px;
      border-radius: 24px;
      border-color: #7d868e;
    }
    &:after {
      top: calc(4px + 2px);
      left: calc(-48px + 4px);
      width: calc(24px - 4px);
      height: calc(24px - 4px);
      border-radius: 24px;
      background-color: #7d868e;
    }
  }
  .custom-control-input {
    &:checked {
      ~.custom-control-label {
        &:before {
          color: #fff;
          border-color: $color-info;
          background-color: $color-info;
        }
        &:after {
          transform: translateX(20px);
        }
      }
    }
    &:focus {
      &:not(:checked) {
        ~.custom-control-label {
          &:before {
            border-color: $color-info;
          }
        }
      }
      ~.custom-control-label {
        &:before {
          box-shadow: 0 0 0 0.2rem rgba($color-info, 0.25);
        }
      }
    }
  }
}

.custom-switch-sm{
  display: inline-block;
  line-height: 20px;
  padding-left: 40px;
  .custom-control-input{
    &:checked ~ .custom-control-label{
      &:after{
        transform: translateX(19px);
      }
    }
  }
  .custom-control-label{
    &:before{
      top: 0;
      left: calc(-42px + 2px);
      width: 40px;
      height: 22px;
      border-radius: 22px;
    }
    &:after{
      top: 2px;
      left: calc(-42px + 4px);
      width: calc(22px - 4px);
      height: calc(22px - 4px);
      border-radius: 22px;
    }
  }
}

input.number {
  &placeholder { /* Chrome/Opera/Safari */
    color: pink;
  }
}

/*configure metadata*/

.textedit-icon{
  position: relative;
  textarea{
    padding-right: 60px;
    resize: none;
  }
  .icon-box{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #F2F2F2;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    color: #9D9D9D;
  }
}
.rotate-key{
  transform: rotate(315deg);
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered{
  padding: 0.65rem 1rem;
}

/*clode option*/
.has-close-option {
  width: 100%;
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  span {
    width: calc(100% - 30px);
    display: inline-block;
  }
  i {
    font-size: 16px;
    width: 24px;
    height: 24px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    cursor: pointer;
    position: relative;
    &:before{
      content: '';
      width: 22px;
      height: 22px;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 11px;
      border: 3px solid transparent;
      transform: translate(-50%,-50%);
    }
    &:hover{
      &:before{
        border: 3px solid rgba(246, 78, 96, 0.15);
      }
    }
  }
}

/*Input group with security eye*/
.input-group {
  &.has-append-icon {
    &> .form-control {
      &:not(:last-child) {
        padding-right: 30px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .input-group-append-icon {
      position: relative;
      i {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ccc;
        cursor: pointer;
        z-index: 3;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%)
      }
    }
  }
}

/* Input Tags */

.qd_tags-input {
  @include flexbox();
  @include align-items(center);
  @include flex-wrap(wrap);
  width: 100%;
  min-height: 44px;
  padding: 6px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  overflow: hidden;
  background-color: $white;
  &:focus-within {
    border-color: #80bdff;
  }
  input {
    flex: 1;
    border: none;
    font-size: 14px;
    padding: 0 6px;
    &:focus {
      outline: transparent;
    }
  }
  .tag-list {
    @include flexbox();
    @include flex-wrap(wrap);
    padding: 0;
    margin: 0;
    list-style: none;
    .tag-item {
      list-style: none;
    }
  }
  .tag {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    width: auto;
    padding: 6px 10px;
    border-radius: 4px;
    margin: 4px;
    background-color: #f3f3f3;
    .tag-title {
      font-size: 14px;
      color: $color-dark;
    }
    .tag-close-icon {
      position: relative;
      width: 24px;
      height: 24px;
      margin-left: 12px;
      opacity: 0.5;
      transform: scale(1);
      cursor: pointer;
      &:before,&:after {
        content: '';
        position: absolute;
        left: 12px;
        top: 6px;
        width: 1px;
        height: 12px;
        background-color: #000;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
      &:hover {
        opacity: 0.75;
        transform: scale(1.2);
      }
    }
  }
}
@media (max-width: 575.98px) {
  .qd_tags-input {
    min-height: 34px;
    .tag {
      padding: 4px 8px;
      .tag-close-icon {
        margin-left: 8px;
      }
    }
  }
}

/* Icon Info Circle */
.icon-info-circle {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  background: url('../../assets/img/icons/icon_info-circle.svg') center center no-repeat;
  background-size: 24px;
}
.form-label {
  .icon-info-circle {
    margin-left: 4px;
    background-size: 18px;
  }
}