/* Select */
.select2-container .select2-selection--single {
  font-size: 14px; }
.select2-container--bootstrap4 .select2-selection--single {
  height: 34px !important; }
  .select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered,
  .select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
    line-height: 32px; }

/* Select2dropdown */
.select-control__value-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important; }
.select-control__control {
  min-height: 34px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  .select-control__placeholder{
    color: #c9c9c9;
  }
  &.select-control__control--is-focused{
    border-color: #80bdff !important;
  }
  &:focus{
    border-color: #80bdff !important;
  } }
    .select-control__indicator {
      padding: 6px !important; }
      .select-control__indicator-separator { 
        display: none; }
        .select-control__multi-value {
          border-radius: 20px; }
          .select-control__multi-value__label { 
            padding-left: 10px; }
            .select-control__multi-value__remove {
              background-color: transparent;
              cursor: pointer;
              color: #000;}
              .col-select-control__placeholder-sm{
                .select-control__placeholder{
                  font-size: 12px;
                  // white-space: nowrap;
                  // overflow: hidden;
                  // text-overflow: ellipsis;
                  // width: 100%;
                }
              }

/*selct control*/

.select-control__menu{
  z-index: 2 !important;
}