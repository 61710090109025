// .table-bordered thead td,
// .table-bordered thead th,
// .table-bordered tbody th {
//   font-weight: 500; }
//   .table-bordered tbody th {
//     text-align: center; }
//     .table td, .table th {
//       font-size: 12px; }


.qd-table__controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.qd-table__table-controls {
  margin-bottom: 15px;
  .table-controls__left-content {
    width: 30%;
    display: flex;
    justify-content: flex-start;
  }
  .table-controls__right-content {
    // width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  .qd-search-bar {
    width: 200px;
  }
  .table-controls__filter {
    width: 220px;
  }
  .table-controls__subtle-text {
    margin: 10px 0;
  }
  .btn {
    height: 34px;
    padding: 4px 14px;
  }
}

.qd-table {
  border: 1px solid #ecf0f3;
  thead {
    th {

    }
    td, th {
      font-weight: 500;
      // background-color: #f5f8fa;
      font-size: 12px;
      text-transform: uppercase;
      // color: #353f45;
      color: #979797;
      padding: 10px 10px;
      border-width: 0 0 1px 0;
      border-color: #ecf0f3;
    }
  }
  tbody {
    th {
      font-weight: 500;
      text-align: center;
      padding: 10px;
    }
  }
  // thead,
  tbody {
    th, td {
      color: #464e5f;
      font-size: 13px;
      font-weight: 500;
      border: none;
      // border: 1px solid #f4f4f4;
      // border-width: 0 0 1px 0;
      vertical-align: middle;
    }
  }
  .btn {
  }
  .text-muted {
    color: #919191 !important;
    font-weight: normal;
  }
}

/* React table */
.react-bootstrap-table {

  table {
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 0 4px;
    white-space: nowrap;
    font-size: 12px;
    th {
      line-height: 30px;
      color: #484848;
      .order{
        &>.dropdown{
          &>.caret{
            position: relative;
            top: 3px;
            left: 4px;
          }
        }
        &>.dropup{
          &>.caret{
            position: relative;
            top: -4px;
            left: -9px;
          }
        }
      }
    }
    td {
      font-weight: 400;
      color: #484848;
    }
    td, th {
      padding: 12px 10px;
      border: none;
      &:first-child {
        text-align: center !important;
      }
    }
    thead {
      th{
        color: rgba(0, 123, 255, 0.75);
        margin-bottom: 5px;
        border: 0;
        letter-spacing: 0.5px;
        padding: 8px 10px;
        font-weight: 500;
        background-color: #edf5ff;
        &:focus {
          outline: none;
        }
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
    tbody {
      tr {

        background-color: #f9fbfd;

        // &:nth-of-type(2n+1) {
        //   background-color: #fff;
        // }
        // &:nth-of-type(2n+0) {
        //   background-color: #fff;
        // }
        > td {
          background-color: #f9fbfd;
          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
        &.row-highlight {
          // background-color:#e3f2ff !important;
          background-color: #edf2f7 !important;
        }
      }
    }
    dl {
      dt {
        font-weight: 500;
        color: $gray-light;
      }
      dd {
        .data-progress-status {
          margin: 10px 0 0;
        }
      }
    }
    .data-progress-status {
      margin: 0;
      label {
        margin-bottom: 2px;
      }
      .progress {
        max-width: 200px;
        margin: 0;
        height: 14px;
        padding: 3px;
        box-shadow: 1px 0px 4px 0px #d0d0d0;
      }
      .progress-bar {
        height: 8px;
      }
    }
    .table-vertical-btns {
      justify-content: start;
      max-height: 194px;
      overflow-y: auto;
      padding-right: 12px;
      width: 175px;
      > .btn:not(:last-child):not(.dropdown-toggle),
      > .btn:not(:first-child) {
        border-radius: .25rem;
        border-radius: .25rem;
      }
      .btn {
        font-size: 12px;
        padding: 4px 20px;
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        + .btn {
          margin-left: 0;
          margin-top: 5px;
        }
      }
    }
    .btn-light {
      color: #737373;
      background-color: rgba(72, 72, 72, 0.02);
      border-color: rgba(72, 72, 72, 0.1);
      &:hover,
      &:focus,
      &:active {
        color: #737373;
      }
    }
    .btn-link {
      &:hover {
        box-shadow: none;
      }
    }
    .btn-outline-secondary {
      color: lighten($brand-secondary, 10%);
      border-color: lighten($brand-secondary, 10%);
      background-color: #FFF;
      &:hover,
      &:focus,
      &:active   {
        color: lighten($brand-secondary, 10%);;
        border-color: lighten($brand-secondary, 10%);
        background-color: #FFF;
      }
    }
    &.table-striped  {
      tbody {
        tr:nth-of-type(odd) {
          background-color: rgba(0,0,0,.02);
        }
      }
    }
    .badge {
      width: max-content;
      padding: 2px 6px;
      border-radius: 4px;
      height: auto;
      line-height: normal;
    }
    a {
      color: #007bff;
    }
    /* AB Tested table */
    &.abtested-table {
      tr {
        position: relative;
      }
      .abtested_cell {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          height: 100%;
          width: 1px;
          border-left: 1px solid #e1edfc;
          top: 0;
          left: 20px;
        }
        &.abtested_start {
          &:before {
            top: 50%;
          }
        }
        &.abtested_end {
          &:before {
            top: auto;
            bottom: 50%;
          }
        }
      }
      .abtest_parent,
      .abtest_child {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #000;
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        margin-right: 5px;
        position: relative;
        z-index: 1; 
      }
      .abtest_parent {
        background-color: $brand-primary;
      }
      .abtest_child {
        background-color: #3E9BFE;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        font-size: 10px;
        margin: 2px 7px 2px 2px;
      }
    }
    // Custom Row span style
    &.table_custom-rowspan {
      border-spacing: 0;
        tr {
          &:first-child {
            td {
              border-top: 4px solid #fff;
            }
          }
        }
        td {
          padding: 0;
          > div {
            padding: 12px 11px;
            border-bottom: 4px solid #fff;
            min-height: 53px;
          }
        }
    }
    //Action More
    .tbl_action-more {
      display: inline-block;
      .dropdown-menu {
        padding: 5px 0;
      }
      .dropdown-item {
        padding: 5px 12px;
        font-size: 12px;
        color: #707070;
      }
    }
  }
  // &.action-sticky-cell{
  //   thead{
  //     th{
  //       &:last-child {
  //         position: sticky;
  //         right: 0px;
  //         transition: left 0.2s;
  //       }
  //     }
  //   }
  //   tbody{
  //     tr{
  //       > td{
  //         &:last-child {
  //           position: sticky;
  //           right: 0px;
  //           transition: left 0.2s;
  //         }
  //       }
  //     }
  //   }
  // }
  &__header {
    margin-bottom: 10px;
    .react-table__length,
    .react-table__filter {
      @include flexbox();
      @include align-items(center);
      color: #6C7786;
    }
    .react-table__length {
      .select-control__control { 
        margin: 0 5px;
        color: rgba(108, 117, 125, 0.5);
      }
    }
    .react-table__filter {
      position: relative;
      text-align: right;
      @include justify-content(flex-end);
      .table-search{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          top: 50%;
          left: 12px;
          width: 16px;
          height: 16px;
          transform: translateY(-50%);
          background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgba(108, 117, 125, 0.3)" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>');
          background-position: top left;
          background-size: 16px 16px;
          background-repeat: no-repeat;
        }
      }
      .form-control {
        width: 175px;
        margin-left: 5px;
        padding-left: 1.875rem;
      }
    }
  }
  &__footer {
    .react-table__info {
      color: #6C7786;
      // margin-top: 5px;
      // margin-bottom: 20px;
      display: inline-block;
      b {
        font-weight: 600;
      }
    }
    .pagination {
      .page-item {
        margin-right: 5px;
        &:last-child{
          margin-right: 0;
        }
        .page-link {
          outline: none;
          position: relative;
          padding: 0;
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 3px;
          color: #93a2dd;
          border: none;
          z-index: auto;
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
          &:hover {
            color: #007bff;
            background-color: #e4e7f0;
            border: 1px solid #e4e7f0;
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        &[title^="first"],
        &[title^="previous"],
        &[title^="next"],
        &[title^="last"] {
          .page-link {
            text-indent: -9999px;
            border-color: #eff2fc;
            background-color: #eff2fc;
            display: block;
            &:hover {
              border-color: #e4e7f0;
              background-color: #e4e7f0;
            }
            &.active {
              background-color: #2f6bd6;
            }
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 18px;
              height: 18px;
              margin: -9px 0 0 -9px;
            }
          }
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &[title^="first"] {
          .page-link {
            &:before {
              background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-left" class="svg-inline--fa fa-angle-double-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="rgba(47, 107, 214, 0.75)" d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path></svg>');
              background-size: auto;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
        &[title^="previous"] {
          .page-link {
            &:before {
              background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="rgba(47, 107, 214, 0.75)" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>');
              background-size: auto;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
        &[title^="next"] {
          .page-link {
            &:before {
              background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="rgba(47, 107, 214, 0.75)" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>');
              background-size: auto;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
        &[title^="last"] {
          .page-link {
            &:before {
              background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-right" class="svg-inline--fa fa-angle-double-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="rgba(47, 107, 214, 0.75)" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z"></path></svg>');
              background-size: auto;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
        &.active {
          .page-link {
            color: #fff;
            border: none;
            &:hover {
              background-color: #007bff;
              color: #fff;
              border-color: transparent;
            }
          }
        }
      }
    }
  }
  &.tbl1col_text-left {
    table {
      th, td {
        &:first-child {
          text-align: left !important;
        }
      }
    }
  }
}
.has-table-error {
  position: relative;
  td {
    background-color:#FEF5F6 !important;
    border: 1px solid #FEECEE !important;
    border-width: 1px 0 !important;
    color: $color-danger !important;
    &:first-child {
      border-width: 1px 0 1px 1px !important;
    }
    &:last-child {
      border-width: 1px 1px 1px 0 !important;
    }
  }
}
.has-table-warning {
  position: relative;
  td {
    background-color:#FEF5F6 !important;
    border: 1px solid #FEECEE !important;
    border-width: 1px 0 !important;
    color: $color-warning !important;
    &:first-child {
      border-width: 1px 0 1px 1px !important;
    }
    &:last-child {
      border-width: 1px 1px 1px 0 !important;
    }
  }
}
@media (min-width: 1200px){
  .react-bootstrap-table{
    &.action-sticky-cell{
      thead{
        th{
          &:last-child {
            position: sticky;
            right: 0px;
            transition: left 0.2s;
          }
        }
      }
      tbody{
        tr{
          > td{
            &:last-child {
              position: sticky;
              right: 0px;
              transition: left 0.2s;
              z-index: 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  .react-bootstrap-table{
    &__footer{
      .react-table__info{
        display: block;
        text-align: center;
      }
      .pagination{
        float: none;
        @include justify-content(center);
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .react-bootstrap-table{
    &__header{
      .react-table__length,.react-table__filter{
        span{
          display: none;
        }
      }
    }
  }
}

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  .dropup {
    .caret {
      margin: 10px 0 10px 3px !important;
      border-bottom: 5px dashed #B2B9C1;
    }
  }
  .dropdown {
    .caret {
      margin: 10px 0 10px 3px !important;
      border-top: 5px dashed #B2B9C1;
    }
  }
.react-bootstrap-table-sort-order {
  .caret {
    margin: 10px 8px !important;
    border-top: 5px dashed #7B8A9A;
  }
  &.dropup {
    .caret {
      margin: 10px 8px !important;
      border-top: 0px !important;
      border-bottom: 5px dashed #7B8A9A;
    }
  }
}

/*configure meta data*/

.react-bootstrap-table table {
  .data-progress-percentage{
    display: flex;
    align-items: center;
    height: 20px;
    .progress{
      width: 100px;
      height: 8px;
      border-radius: 5px;
      background-color: rgba(115, 136, 169, 0.35);
    }
    .progress-bar{
      height: 8px;
      border-radius: 5px;
      background-color: #147AD6;
      animation: 3s normal forwards;
    }
    .data-progress-value{
      margin: 0;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .textedit-icon {
    .icon-box{
      width: 20px;
      height: 20px;
      font-size: 8px !important;
      right: 12px;
      i{
        font-size: 8px;
      }
    }
  }
  textarea{
    max-width: 300px;
    padding-right: 30px;
    line-height: 10px;
    background-color: transparent;
    position: relative;
    top: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0;
    padding: 0 35px 0 0;
    resize: none;
  }
}


/*monitor*/
.react-bootstrap-table table {
  .btn-outline-royalblue{
    background-color: rgba(73, 95, 184, 0.15);
    color: #495FB8;
    border-color: rgba(73, 95, 184, 0.5);
    &:hover{
      background-color: rgba(73, 95, 184,0.5);
      border-color: rgba(73, 95, 184, 0.8);
    }
  }
  .btn{
    font-size: 11px;
    padding: 3px 8px;
  }
  dl dd strong{
    font-weight: 600;
  }
}
/*btn seemore*/
.table__seemore {
  border-top: 1px solid #F0F7FF;
  margin-bottom: 15px;
  .btn {
    background-color: #F0F7FF;
    border-radius: 0 0 6px 6px;
    padding: 5px 12px;
    transition: 0.3s all;
    color: #007FFF;
    &:hover {
      text-decoration: none;
    }
    &> i {
      margin-left: 5px;
    }
    &.active > i svg {
      transform: rotateZ(90deg);
    }
  }
}