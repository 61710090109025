


//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$white:                 #fff !default;
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

// $brand-primary:         #0021A5 !default;
$brand-primary:         #FA4616 !default;
// $brand-secondary:       #007BFF !default;
$brand-secondary:       #0021A5 !default;

// $color-success:         #5cb85c !default;
// $color-info:            #5bc0de !default;
// $color-warning:         #f0ad4e !default;
// $color-danger:          #d9534f !default;

$color-success:         #4ED2CC !default;
$color-info:            #4CA3FF !default;
$color-warning:         #FFA800 !default;
$color-danger:          #F64E60 !default;
$color-light:           #e5eaee !default;
$color-dark:            #484848 !default;

$color-purple:          #915EF9 !default;
$color-royalblue:       #495FB8 !default;
$color-gray:            #7D7D7D !default;
$color-orange:          #E64A19 !default;
$color-brown:           #795548 !default;
$color-blue:            #007BFF !default;
$color-green:           #198754 !default;

$base-text-color:       #484848 !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               $white !default;
//** Global text color on `<body>`.
$text-color:            $gray-dark !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;


//== Typography
//

$font-family-poppins:  'Poppins', sans-serif !default;
$font-family-base:     $font-family-poppins !default;

$font-size-base:          14px !default;

:root {
    --page-bg-theme: linear-gradient(90deg, #2651C2, #3D94F7);
    --sticky-header-bg: linear-gradient(to right, rgba(33,62,177,1) 0%, rgba(52,150,255,1) 100%);
    // --help-header-bg: linear-gradient(-45deg, #4695ff, #1970e2);
    // --help-header-img: linear-gradient(45deg, #318aff, #247cef);
}

.theme-orange {
    --page-bg-theme: linear-gradient(90deg, #FA4616, rgba(250, 70, 22, 0.7));
    --sticky-header-bg: linear-gradient(to right, #FA4616 0%, #F37351 100%);
    // --help-header-bg: linear-gradient(-45deg, #F37351, #FA4616);
    // --help-header-img: linear-gradient(45deg, #F27C5D, #F75529);
}