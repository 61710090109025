// html {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  background-color: #eef0f8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body a,
body button,
html a,
html button {
  outline: 0 !important;
}

a {
  color: #5867dd;
  text-decoration: none;
  background-color: transparent;
}

strong {
  font-weight: 500;
}
.container-fluid {
  padding: 0 30px;
}


.bg-light-dark {
  background-color: #949494 !important;
}
.text-primary{
  color: $brand-primary !important;
}
.text-secondary{
  color: $brand-secondary !important;
}
.text-info {
  color: $color-info !important;
}
.text-success {
  color: $color-success !important;
}
.text-warning {
  color: $color-warning !important;
}
.text-danger {
  color: $color-danger !important;
}
.text-dark {
  color: $base-text-color !important;
}
.text-purple {
  color: $color-purple !important;
}
.text-royalblue {
  color: $color-royalblue !important;
}
.text-gray {
  color: $color-gray !important;
}
.text-orange {
  color: $color-orange !important;
}
.text-brown {
  color: $color-brown !important;
}
.text-blue {
  color: $color-blue !important;
}
.text-green{
  color: $color-green !important;
}
.dashboard-stat-info {
  background-color: rgba($color-info, .08) !important;
  border-color: rgba($color-info, .1) !important;
  .help-icon{
    background-color: rgba($color-info, 0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-info, 0.5);
    }
  }
}
.dashboard-stat-success {
  background-color: rgba($color-success, .08) !important;
  border-color: rgba($color-success, .1) !important;
  .help-icon{
    background-color: rgba($color-success,0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-success, 0.5);
    }
  }
}
.dashboard-stat-warning {
  background-color: rgba($color-warning, .08) !important;
  border-color: rgba($color-warning, .1) !important;
  .help-icon{
    background-color: rgba($color-warning, 0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-warning, 0.5);
    }
  }
}
.dashboard-stat-danger {
  background-color: rgba($color-danger, .08) !important;
  border-color: rgba($color-danger, .1) !important;
  .help-icon{
    background-color: rgba($color-danger, 0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-danger, 0.5);
    }
  }
}
.dashboard-stat-purple {
  background-color: rgba($color-purple, .08) !important;
  border-color: rgba($color-purple, .1) !important;
  .help-icon{
    background-color: rgba($color-purple, 0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-purple, 0.5);
    }
  }
}
.dashboard-stat-royalblue {
  background-color: rgba($color-royalblue, .08) !important;
  border-color: rgba($color-royalblue, .1) !important;
  .help-icon{
    background-color: rgba($color-royalblue, 0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-royalblue, 0.5);
    }
  }
}
.dashboard-stat-gray {
  background-color: rgba($color-gray, .08) !important;
  border-color: rgba($color-gray, .1) !important;
  .help-icon{
    background-color: rgba($color-gray, 0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-gray, 0.5);
    }
  }
}
.dashboard-stat-brown {
  background-color: rgba($color-brown, .08) !important;
  border-color: rgba($color-brown, .1) !important;
  .help-icon{
    background-color: rgba($color-brown, 0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-brown, 0.5);
    }
  }
}
.dashboard-stat-orange {
  background-color: rgba($color-orange, .08) !important;
  border-color: rgba($color-orange, .1) !important;
  .help-icon{
    background-color: rgba($color-orange, 0.10) !important;
    border-color: rgba(#707070, 0.10) !important;
    i{
      color: rgba($color-orange, 0.5);
    }
  }
}
.circle-number-gray{
  background-color: rgba($color-gray, 0.2) !important;
  border-color: rgba($color-gray, 1) !important;
  &:before{
    background-color: rgba($color-dark, 0.8) !important;
  }
}
.circle-number-gradient{
  background-color: rgba(#FA4616, 0.1) !important;
  border-color: rgba(#FA4616, 0.1) !important;
  &:before{
    background: linear-gradient(to bottom,#FA4616,#FC651E,#FF9D2C);
  }
}
.primary-payment{
  background-color: rgba($color-success, 0.02) !important;
  border: 1px solid rgba($color-success, 0.5) !important;
}

/*Edition Card */
.your-plan__professional{
  border-color: rgba($brand-primary, 0.15) !important;
  background-color: #FFF4F1 !important;
  &:before{
    content: '';
    position: absolute;
    right: -70px;
    top: -100px;
    border-radius: 50%;
    height: 12.5rem;
    width: 12.5rem;
    background-image: -webkit-linear-gradient(45deg,#fdf3ef,#ffefec);
    background-image: linear-gradient(45deg,#fdf3ef,#ffefec);
    z-index: -1;
  }
  &:after{
    content: '';
    position: absolute;
    left:-80px;
    top: 25px;
    border-radius: 50%;
    height: 18rem;
    width: 18rem;
    background-image: -webkit-linear-gradient(45deg,#fdf3ef,#ffefec);
    background-image: linear-gradient(45deg,#fdf3ef,#ffefec);
    z-index: -1;
  }
  .card-header{
    color: rgba($brand-primary, 0.75) !important;
    background-color: transparent !important;
    border-bottom: 1px solid rgba($brand-primary, 0.15) !important;
  }
}
.your-plan__starter{
  border-color: rgba($brand-secondary, 0.15) !important;
  background-color: #EEF0F8 !important;
  &:before{
    content: '';
    position: absolute;
    right: -70px;
    top: -100px;
    border-radius: 50%;
    height: 12.5rem;
    width: 12.5rem;
    background: rgba(93, 64, 55, 0.02);
    z-index: -1;
  }
  &:after{
    content: '';
    position: absolute;
    left:-80px;
    top: 25px;
    border-radius: 50%;
    height: 18rem;
    width: 18rem;
    background: rgba(93, 64, 55, 0.02);
    z-index: -1;
  }
  .card-header{
    color: rgba($brand-secondary, 0.75) !important;
    background-color: transparent !important;
    border-bottom: 1px solid rgba($brand-secondary, 0.15) !important;
  }
}
.your-plan__free{
  border-color: rgba(112, 112, 112, 0.15) !important;
  background-color: #F4F4F4 !important;
  .card-header{
    color: rgba(112, 112, 112, 0.75) !important;
    background-color: transparent !important;
    border-bottom: 1px solid rgba(112, 112, 112, 0.15) !important;
  }
}
@media(max-width: 576px){
  .your-plan__starter{
    &:before{
      top: -110px;
    }
    &:after{
      height: 15rem;
      width: 15rem;
    }
  }
  .your-plan__professional{
    &:before{
      top: -110px;
    }
    &:after{
      height: 15rem;
      width: 15rem;
    }
  }
}

/* Background */
.bg-success{
  background-color: $color-success !important;
}
.bg-info{
  background-color: $color-info !important;
}
.bg-warning{
  background-color: $color-warning !important;
}
.bg-danger{
  background-color: $color-danger !important;
}
.bg-light{
  background-color: $color-light !important;
}
.bg-dark{
  background-color: $color-dark !important;
}
.bg-purple{
  background-color: $color-purple !important;
}
.bg-royalblue{
  background-color: $color-royalblue !important;
}
.bg-gray{
  background-color: $color-gray !important;
}
.bg-orange{
  background-color: $color-orange !important;
}
.bg-brown{
  background-color: $color-brown !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}
.btn.btn-link{
  &:hover{
    box-shadow: none;
  }
}

@media (max-width: 575.98px) {
  .container-fluid {
    padding: 0 15px;
  }
}

.bdr-dashed {
  border-bottom: 1px dashed #6c757d;
}
.bdr-dashed-purple {
  border-bottom: 1px dashed #915EF9;
}
.bdr-dashed-danger {
  border-bottom: 1px dashed #F64E60;
}
.bdr-dashed-success {
  border-bottom: 1px dashed #4ED2CC;
}
.bdr-dashed-info {
  border-bottom: 1px dashed #4CA3FF;
}
.bdr-dashed-warning {
  border-bottom: 1px dashed #FFA800;
}

.badge {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  min-width: 18px;
  height: 18px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: normal;
  padding: 1px 4px;
  color: #fff;
  background-color: #7e7d7d;
}
.badge-info{
  background-color: #0D6EFD;
}
/* Badge Success */
.badge-success {
  background-color: $color-success;
}
.badge-light-success {
  background-color: rgba($color-success, .1);
  color: $color-success;
}
.badge-light-danger {
  background-color: rgba($color-danger, .1);
  color: $color-danger;
}
/* Badge Danger */
.badge-danger {
  background-color: $color-danger;
}
/*Popover*/
.popover.popover-lg{
  max-width: 600px;
}

.title-text{
  position: absolute; 
  top: 3px; 
  right: 3px; 
  color: #fff; 
  font-size: 13px
}

/* Blinker Animation */
.text-blinker {
  animation: blinker 1s linear infinite;
}

.z-index-1 { z-index : 1 !important }